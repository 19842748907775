import { useState } from 'react';
// material
import { Button, Container, Stack, Typography } from "@mui/material";
// components
import { useNavigate } from "react-router-dom";
import Alert from '@mui/material/Alert';

import Page from '../components/Page';
import OnboardingSteps from "../ui-component/OnboardingSteps";
import {useLanguage} from "../context/LanguageContext";

// ----------------------------------------------------------------------

export default function Permissions() {
  const { t } = useLanguage();
  const navigate = useNavigate()
  const [messageType, setMessageType] = useState('info');
  const permissionsTitle = t('onboarding.title-1'); // Mic Permission / On-boarding
  const permissionsHeading = t('onboarding.head-1'); // On-boarding / Mic Permissions
  const micErrorMessage = t('micErrorMessage'); //  If you have accidentally disallowed permissions, then you can allow them from clicking top left corner of search bar of this tab
  const allowPermissionLabel = t('allowPermissionLabel'); // Allow mic permissions
  const allowPermissions = async () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(async stream => {
        setMessageType('success');
        stream.getTracks().forEach(track => {
          track.stop();
        });
        navigate('/onboard/language')
      })
      .catch(async () => {
        setMessageType('error');
      });
  };

  return (
    <Page title={permissionsTitle}>
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
            {permissionsHeading}
        </Typography>
        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" >
          <OnboardingSteps />
        </Stack>
      </Container>
      <br />
      <br />
      <br />
      <Container>
        <center><Button  variant="contained" size="large" onClick={allowPermissions}>{allowPermissionLabel}</Button></center>
      </Container>
      <br />
      <br />
      <Container>
       <Alert severity={messageType}>
         {micErrorMessage}
        </Alert>
      </Container>
    </Page>
  );
}
