import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

export default function RecipeReviewCard({item}) {
  return (
    <Card sx={{ maxWidth: 345, margin: 'auto' }}>
      <CardHeader
        style={{padding: '1rem'}}
        title={item.Name}
        subheader=""
      />
      <CardMedia
        component="img"
        height="194"
        image={item.Image}
        alt={item.Name}
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {item.Desc}
        </Typography>
      </CardContent>
    </Card>
  );
}
