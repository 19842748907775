import React, { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { makePaymentForVoiceTyping } from '../services/stripeService';
import { useAuth } from '../context/AuthContext';

let timer;

const CustomSocialProof = ({ info }) => {
  return (
    <section className="custom-social-proof">
      <Paper className="custom-notification">
        <div className="custom-notification-container">
          <div className="custom-notification-image-wrapper">
            <Avatar alt="Custom Logo" src="https://tidings.today/wp-content/uploads/2018/08/tidings-today-logo-fav.png" />
          </div>
          <div className="custom-notification-content-wrapper">
            <Typography className="custom-notification-content" variant="body1">
              Someone from {info.location}
              <br />
              Purchased <strong>Premium Plan</strong> <small>{info.time}</small>
            </Typography>
          </div>
        </div>
        <div className="custom-close"></div>
      </Paper>
    </section>
  );
};

const customerEntries = [
    { location: 'New York, USA', time: '30 minutes ago' },
    { location: 'London, UK', time: '1 hour ago' },
    { location: 'Tokyo, Japan', time: '45 minutes ago' },
    { location: 'Sydney, Australia', time: '2 hours ago' },
    { location: 'Paris, France', time: '20 minutes ago' },
    { location: 'Toronto, Canada', time: '50 minutes ago' },
    { location: 'Berlin, Germany', time: '1 hour ago' },
    { location: 'Seoul, South Korea', time: '25 minutes ago' },
    { location: 'Mumbai, India', time: '40 minutes ago' },
    { location: 'Sao Paulo, Brazil', time: '2 hours ago' },
    { location: 'Moscow, Russia', time: '35 minutes ago' },
    { location: 'Amsterdam, Netherlands', time: '1 hour ago' },
    { location: 'Buenos Aires, Argentina', time: '55 minutes ago' },
    { location: 'Rome, Italy', time: '1 hour ago' },
    { location: 'Dubai, UAE', time: '1 hour ago' },
    { location: 'Mexico City, Mexico', time: '30 minutes ago' },
    { location: 'Beijing, China', time: '1 hour ago' },
    { location: 'Cairo, Egypt', time: '45 minutes ago' },
    { location: 'Stockholm, Sweden', time: '50 minutes ago' },
    { location: 'Cape Town, South Africa', time: '2 hours ago' },
    { location: 'Bangkok, Thailand', time: '55 minutes ago' },
    { location: 'Istanbul, Turkey', time: '1 hour ago' },
    { location: 'Rio de Janeiro, Brazil', time: '40 minutes ago' },
    { location: 'Barcelona, Spain', time: '1 hour ago' },
    { location: 'Singapore', time: '20 minutes ago' },
    { location: 'Prague, Czech Republic', time: '1 hour ago' },
    { location: 'Helsinki, Finland', time: '50 minutes ago' },
    { location: 'Warsaw, Poland', time: '30 minutes ago' },
    { location: 'Oslo, Norway', time: '55 minutes ago' },
    { location: 'Budapest, Hungary', time: '1 hour ago' }
  ];

function getRandomIndex() {
  return Math.floor(Math.random() * customerEntries.length);
}


const FakeSalesAlert = () => {
 const { currentUser } = useAuth();
  const [open, setOpen] = useState(false);
  const [currentEntryIndex, setCurrentEntryIndex] = useState(0);

  useEffect(() => {
    if (!timer) {
      timer = setInterval(() => {
        setCurrentEntryIndex(getRandomIndex());
        if (!currentUser?.paid) {
          setOpen(true);
        }
      }, 3000); // Show the alert every 3 seconds
    }
    return () => {
      clearInterval(timer);
    };
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
     makePaymentForVoiceTyping(currentUser.email).catch(() => {})
  };

  return (
    <Snackbar
      style={{ cursor: 'pointer' }}
      onClick={handleClick}
      open={open}
      autoHideDuration={4000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <Alert onClose={handleClose} severity="success">
        <CustomSocialProof info={customerEntries[currentEntryIndex]} />
      </Alert>
    </Snackbar>
  );
};

export default FakeSalesAlert;
