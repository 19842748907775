import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import languageReducer from './languageReducer';
import preferredLanguageReducer from './preferredLanguageReducer';
import voicetypingReducer from './voicetypingReducer';

// ===========================|| COMBINE REDUCER ||=========================== //

const reducer = combineReducers({
  customization: customizationReducer,
  language: languageReducer,
  pLanguage: preferredLanguageReducer,
  voice: voicetypingReducer,
});

export default reducer;
