import { useState } from 'react';
// material
import {
  Card,
  Table,
  Stack,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Chip,
  Button,
  Tooltip,
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
import useCommands from '../hooks/useCommands';
import { useLanguage } from '../context/LanguageContext';
import useTextReplacement from '../hooks/useTextReplacement';
import Iconify from '../components/Iconify';
import AddCommandPopup from '../ui-component/AddCommandPopup';
import { useAuth } from '../context/AuthContext';
import AlertForPremium from '../ui-component/AlertForPremium';
import { updateUser } from '../services/firebaseService';
import constants from '../constants';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return array.filter((command) => {
      const commandName = Array.isArray(command.match)
        ? command.match.join(', ').toLowerCase()
        : command.match.toLowerCase();
      return commandName.indexOf(query.toLowerCase()) !== -1;
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Commands() {
  const { t } = useLanguage();
  const { currentUser } = useAuth();
  const defaultCommands = useCommands();
  const defaultTextReplacements = useTextReplacement();
  const [customCommands, setCustomCommands] = useState(
    currentUser.commands ? currentUser.commands.map((cmd) => ({ ...cmd, isCustom: true })) : []
  );
  console.log({ customCommands });
  const [commands, setCommands] = useState([...customCommands, ...defaultCommands]);
  const [commandPopUpOpened, setCommandPopUpOpened] = useState(false);
  const [showPay, setShowPay] = useState(false);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [deleting, setDeleting] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState(
    applySortFilter(commands, getComparator(order, orderBy), filterName)
  );
  const commandsTitle = t('commands.title'); // Commands
  const addCommandLabel = t('commands.addCommand'); // Add Command
  const name = t('name'); // Name
  const desc = t('desc'); // Description
  const type = t('type'); // Type
  const lCustom = t('lCustom'); // Custom
  const lDefault = t('lDefault'); // Default

  const addCommandTip = t('buyPremiumToAddCc'); // Buy premium to add custom Commands

  const TABLE_HEAD = [
    { id: 'name', label: name, alignRight: false },
    { id: 'description', label: desc, alignRight: false },
    { id: 'type', label: type, alignRight: true },
  ];

  const addNewCommand = () => {
    const commandsCount = Array.isArray(currentUser.commands) ? currentUser.commands.length : 0;
    if (!currentUser.paid && commandsCount >= constants.freeCustomCommands) {
      setShowPay(true);
      return;
    }
    setCommandPopUpOpened(true);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredUsers
        .filter((cmd) => cmd.isCustom)
        .map((n) => (Array.isArray(n.match) ? n.match.join(', ').toUpperCase() : n.match));
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const deleteSelectedCommands = () => {
    setDeleting(true);
    const allCustomCommands = currentUser.commands ? currentUser.commands : [];
    const remainingCustomCommands = allCustomCommands
      .filter((cmd) => !selected.includes(cmd.match))
      .map((cmd) => ({ ...cmd, isCustom: true }));
    updateUser(currentUser.email, { commands: remainingCustomCommands }).then(() => {
      currentUser.commands = remainingCustomCommands;
      const allCommands = [
        ...remainingCustomCommands.map((cmd) => ({ ...cmd, isCustom: true })),
        ...defaultCommands,
        ...defaultTextReplacements,
      ];
      setCommands(allCommands);
      setCustomCommands(remainingCustomCommands.map((cmd) => ({ ...cmd, isCustom: true })));
      setFilteredUsers(applySortFilter(allCommands, getComparator(order, orderBy), filterName));
      setDeleting(false);
      setSelected([]);
    });
  };

  const saveCommand = async (command) => {
    const allCommandsCustomCommands = currentUser.commands ? currentUser.commands : [];
    allCommandsCustomCommands.unshift(command);
    currentUser.commands = allCommandsCustomCommands;
    const allCommands = [
      ...allCommandsCustomCommands.map((cmd) => ({ ...cmd, isCustom: true })),
      ...defaultCommands,
      ...defaultTextReplacements,
    ];
    setCommands(allCommands);
    setFilteredUsers(applySortFilter(allCommands, getComparator(order, orderBy), filterName));
    await updateUser(currentUser.email, { commands: allCommandsCustomCommands });
    setCustomCommands(allCommandsCustomCommands);
    setCommandPopUpOpened(false);
  };

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <>
      <AlertForPremium
        open={showPay}
        onClose={() => setShowPay(false)}
        error={{ message: 'Please upgrade to create unlimited Custom Commands!' }}
      />
      <AddCommandPopup
        commands={commands}
        onSave={saveCommand}
        open={commandPopUpOpened}
        onClose={() => {
          setCommandPopUpOpened(false);
        }}
      />
      <Page title={commandsTitle}>
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              {commandsTitle}
            </Typography>
            <Tooltip title={!currentUser.paid ? addCommandTip : ''}>
              <Button variant="outlined" startIcon={<Iconify icon="bi:patch-plus-fill" />} onClick={addNewCommand}>
                {addCommandLabel}
              </Button>
            </Tooltip>
          </Stack>
          <Card>
            <UserListToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              onDelete={deleteSelectedCommands}
              deleting={deleting}
            />
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={filteredUsers.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    customCommandsLen={customCommands.length}
                  />
                  <TableBody>
                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const { id, match: name, description, isNew, isCustom } = row;
                      const isItemSelected = selected.indexOf(name) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            {isCustom && (
                              <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, name)} />
                            )}
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2" noWrap>
                                {Array.isArray(name) ? name.join(', ').toUpperCase() : name.toUpperCase()}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">
                            {description} {isNew && <Chip label="New" size="small" variant="outlined" color="info" />}
                          </TableCell>
                          <TableCell align="right">
                            {isCustom ? <Chip variant="outlined" color="primary" label={lCustom} /> : lDefault}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>

                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filteredUsers.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      </Page>
    </>
  );
}
