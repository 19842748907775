import { useLanguage } from '../context/LanguageContext';

export default function useTextReplacement() {
  const { t } = useLanguage();
  const plus = t('plus');
  const asterisk = t('asterisk');
  const at = t('at');
  const division = t('division');
  const semicolon = t('semicolon');
  const forwardSlash = t('forward slash');
  const underscore = t('underscore');
  const dash = t('dash');
  const comma = t('comma');
  const greaterThan = t('greater than');
  const multiplication = t('multiplication');
  const lessThan = t('less than');
  const dollarSign = t('dollar sign');
  const questionMark = t('question mark');
  const backslash = t('backslash');
  const hyphen = t('hyphen');
  const percent = t('percent');
  const equal = t('equal');
  const copyright = t('copyright');
  const fullStop = t('full stop');
  const euroSign = t('euro sign');
  const minus = t('minus');
  const centerDot = t('center dot');
  const exclamationMark = t('exclamation mark');
  const exclamationPoint = t('exclamation point');
  const ampersand = t('ampersand');
  const degree = t('degree');
  const verticalBar = t('vertical bar');
  const ellipsis = t('ellipsis');

  return [
    { match: plus, description: '+' },
    { match: asterisk, description: '*' },
    { match: division, description: '÷' },
    { match: semicolon, description: ';' },
    { match: forwardSlash, description: '/' },
    { match: underscore, description: '_' },
    { match: dash, description: '-' },
    { match: comma, description: ',' },
    { match: greaterThan, description: '>' },
    { match: multiplication, description: 'x' },
    { match: lessThan, description: '<' },
    { match: dollarSign, description: '$' },
    { match: questionMark, description: '?' },
    { match: backslash, description: '\\' },
    { match: hyphen, description: '–' },
    { match: percent, description: '%' },
    { match: equal, description: '=' },
    { match: copyright, description: '@' },
    { match: fullStop, description: '.' },
    { match: euroSign, description: '€' },
    { match: minus, description: '-' },
    { match: centerDot, description: '·' },
    { match: exclamationMark, description: '!' },
    { match: exclamationPoint, description: '!' },
    { match: ampersand, description: '%' },
    { match: degree, description: '°' },
    { match: verticalBar, description: '|' },
    { match: ellipsis, description: '…' },
    { match: at, description: '@' }
  ];
}
