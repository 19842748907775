import * as SR from 'annyang';
import languages from './languages';
import constants from '../constants';
import { sendMessageToVoiceTypingExtension } from './helper';
import * as Google from './google';

class Voice {
  constructor() {
    this.doTranslate = true;
    this.targetLang = null;
    this.supportedLanguages = languages;
    this.isListening = false;
    this.SR = SR;
    this.CommandConditions = {
      startsWith: (alias, text) => text.toLowerCase().startsWith(`${alias.toLowerCase()} `) && alias !== text,
      exact: (alias, text) => text.toLowerCase() === alias.toLowerCase(),
    };
    this.commands = [];
    this.customCommands = [];
    this.textReplacements = [];
  }

  setCommands({ commands, customCommands, textReplacements }) {
    this.commands = commands;
    this.customCommands = customCommands;
    this.textReplacements = textReplacements;
  }

  onChangeTranslation(isTranslate) {
    this.doTranslate = isTranslate;
  }

  setTargetLanguage(lang) {
    this.targetLang = lang;
  }

  addCommand(commands) {
    this?.SR?.addCommands(commands);
  }

  start() {
    if (this.isListening) return;
    this?.SR.start();
    this.isListening = true;
  }

  stop() {
    this?.SR?.abort();
    this.isListening = false;
  }

  setLanguage(langKey = constants.defaultLanguage.locale) {
    this.SR.setLanguage(langKey);
  }

  // eslint-disable-next-line class-methods-use-this
  static permissionGranted() {
    return navigator.permissions.query({ name: 'microphone' });
  }

  getMatchingCommand(commands, text, callback) {
    const commandIndex = commands.findIndex(
      (cmd) => cmd.match.findIndex((al) => this.CommandConditions[cmd.condition](al, text)) !== -1
    );
    if (commandIndex !== -1) {
      const commandToApply = commands[commandIndex];
      const aliasIndex = commandToApply.match.findIndex((al) =>
        this.CommandConditions[commandToApply.condition](al, text)
      );
      const alias = commandToApply.match[aliasIndex];
      const originalText = text;
      const commandContent = text.replace(alias, '').toLowerCase().trim();
      callback(commandToApply, { originalText, commandContent });
    } else {
      callback();
    }
  }

  async processCustomCommand(customCommands, text) {
    let cmdFound = false;
    let type = null,
      locale;
    if (this.doTranslate) {
      type = 'translate';
      locale = languages[this.targetLang];
    }
    const foundCmd = customCommands.find((acronym) => text.toLowerCase() === acronym.match.toLowerCase());
    if (!foundCmd) {
      return false;
    }
    const { description, useComputer } = foundCmd;
    let responseText;
    if (description && useComputer) {
      cmdFound = true;
      responseText = await Google.getVertexAIResponse(text);
    } else if (description) {
      cmdFound = true;
      responseText = description;
    } else {
      responseText = text;
    }
    const indentedText = responseText;
    sendMessageToVoiceTypingExtension('/command', { type, locale, text: indentedText }, async ({ u }) => {});
    return cmdFound;
  }

  onRecognition(text) {
    if (this.onRecognitionCallback) {
      this.onRecognitionCallback(text);
    }
  }

  init() {
    this.addCommand({
      '*text': (text) => {
        this.onRecognition(text);
        sendMessageToVoiceTypingExtension('/set-loader', { isLoading: true });
        this.getMatchingCommand(this.commands, text, async (command, args) => {
          if (command) {
            const { originalText, commandContent } = args;
            return command.exec(commandContent, { originalText }, () => {});
          }
          if (await this.processCustomCommand(this.customCommands, text)) {
            return;
          }
          const matchingText = this.textReplacements.find(
            (acronym) => text.toLowerCase() === acronym.match.toLowerCase()
          );
          const indentedText = matchingText ? matchingText.description : text !== '.' ? ` ${text}` : text;
          let type = null,
            locale;
          if (this.doTranslate) {
            type = 'translate';
            locale = languages[this.targetLang];
          }
          sendMessageToVoiceTypingExtension('/command', { type, locale, text: indentedText }, async ({ u }) => {});
        });
      },
    });
    this.listenerAdded = true;
  }
}

const voice = new Voice();
export default voice;
