import { Button, Snackbar, Alert } from '@mui/material';
import Iconify from './Iconify';
import { useLanguage } from '../context/LanguageContext';
import { useEffect, useState } from 'react';
import { isExtensionInstalled } from '../services/helper';

export default function ExtensionAlert() {
  const { t } = useLanguage();
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const voiceTypingAlertLabel = t('vt.alertLabel'); // Install Voice Typing browser extension to type on other pages!
  const addToChromeLabel = t('addToChromeLabel'); // Add to Chrome
  useEffect(() => {
      isExtensionInstalled().then(isInstalled  => {
        setSnackBarOpen(!isInstalled);
      })
    }, [])
  return (
      <Snackbar open={snackBarOpen} style={{ borderRadius: '4px', border: '1px solid #00000054'}} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert severity="info" sx={{ width: '100%' }} icon={<Iconify icon="logos:chrome" />} action={<Button target="_blank" href="https://chrome.google.com/webstore/detail/voice-typing/hmpihaioaacpehkghnkmnmgmihalkmdf">{addToChromeLabel}</Button>}>
          {voiceTypingAlertLabel}
        </Alert>
      </Snackbar>
  )
}
