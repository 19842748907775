// routes
import '@fontsource/poppins';
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
// project imports
import { LanguageProvider } from './context/LanguageContext';
import { AuthProvider } from './context/AuthContext';
// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeProvider>
      <AuthProvider>
        <LanguageProvider>
          <ScrollToTop />
          <Router />
        </LanguageProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}
