import { useContext } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
// pages
import Commands from './pages/Commands';
import NotFound from './pages/Page404';
import Login from './pages/Login';
import DashboardApp from './pages/DashboardApp';
import DashboardAppStarted from './pages/DashboardAppStarted';
import Permissions from './pages/Permissions';
import Language from './pages/Language';
import Congratulations from './pages/congratulations';
import Terms from './pages/Terms';
import PrivacyPolicy from './pages/Privacy';
import Success from './pages/Success';
import Help from './pages/Help';
import Payment from './pages/Payment';
// import Notes from "./pages/Notes";
import Downloads from './pages/Downloads';
import DateDeletionInstruction from './pages/DateDeletionInstruction';
import Settings from './pages/settings';
import Started from './pages/Started';

// project
import { AuthContext } from './context/AuthContext';
// ----------------------------------------------------------------------

export default function Router() {
  const { currentUser } = useContext(AuthContext);
  const isLoggedIn = !!currentUser;
  return useRoutes([
    {
      path: '/onboard',
      element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { path: 'permission', element: <Permissions /> },
        { path: 'language', element: <Language /> },
        { path: 'congratulations', element: <Congratulations /> },
      ],
    },
    {
      path: '/dashboard',
      element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'app/started', element: <DashboardAppStarted /> },
        { path: 'commands', element: <Commands /> },
        { path: 'settings', element: <Settings /> },
        { path: 'downloads', element: <Downloads /> },
        { path: 'help', element: <Help /> },
        { path: 'payment', element: <Payment /> },
      ],
    },
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'terms',
      element: <Terms />,
    },
    {
      path: 'privacy',
      element: <PrivacyPolicy />,
    },
    {
      path: 'data-deletion-instructions',
      element: <DateDeletionInstruction />,
    },
    {
      path: '/',
      element: isLoggedIn ? <LogoOnlyLayout /> : <Navigate to="/login" />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" /> },
        { path: '/success', element: <Success /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    // {
    //   path: '/',
    //   element: <LogoOnlyLayout />,
    //   children: [
    //     { path: '/notes', element: <Notes /> }
    //   ]
    // },
    {
      path: '/started',
      element: <Started />,
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}
