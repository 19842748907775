import { loadStripe } from '@stripe/stripe-js';
import constants from '../constants';

export default async function Stripe(defaultKey) {
    return loadStripe(defaultKey);
}

async function PaymentFactory(apiKey, options) {
    const stripe = await Stripe(apiKey);
    await stripe.redirectToCheckout({
        ...options,
        mode: 'payment'
    });
}

export const makePaymentForVoiceTyping = async (email) => {
    const apiKey = constants.extension.voice_typing.stripe.apiKey.value;
    const priceId = constants.extension.voice_typing.pricing.priceId.value;
    const paymentConfirmUrl = constants.extension.voice_typing.paymentSuccessURL.value;
    const paymentFailedUrl = constants.extension.voice_typing.paymentCancelURL.value;
    const options = {
        lineItems: [
            {
                price: priceId,
                quantity: 1
            }
        ],
        mode: 'payment',
        customerEmail: email,
        successUrl: paymentConfirmUrl,
        cancelUrl: paymentFailedUrl
    };
    return PaymentFactory(apiKey, options);
};
