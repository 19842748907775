import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useAuth } from '../context/AuthContext';
import { useLanguage } from '../context/LanguageContext';
import Iconify from '../components/Iconify';
import { Stack, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useState } from 'react';
import constants from '../constants';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiPaper-root': {
    width: '100%',
  },
}));

export default function AddCommandPopup({ open, onClose, onSave, commands }) {
  const { t } = useLanguage();
  const { currentUser } = useAuth();
  const [disabled, setDisabled] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [match, setMatch] = React.useState('');
  const [desc, setDesc] = React.useState('');
  const [useComputer, setUseComputer] = React.useState(false);
  const [error, setError] = useState({ isError: false, helperText: '' });
  const newCommandLabel = t('acp.newCommand'); // New Command
  const sayThisLabel = t('acp.sayThis'); // Say this
  const toTypeThisLabel = t('acp.toType'); // To type this
  const saveLabel = t('save'); // Save
  const cancelLabel = t('cancel'); // Cancel
  const gptcheckLabel = t('gptcheckLabel'); // Feed this command text into ChatGPT to receive a response

  const handleClose = () => {
    onClose();
  };

  const doSave = () => {
    const commandsCount = Array.isArray(currentUser.commands) ? currentUser.commands.length : 0;
    if (match === '' || desc === '' || (!currentUser.paid && commandsCount >= constants.freeCustomCommands)) {
      return;
    }
    setLoading(true);
    setDisabled(true);
    const command = {
      match: match.trim().toLowerCase(),
      description: desc,
      useComputer,
    };
    onSave(command).then(() => {
      setLoading(false);
      setDisabled(false);
      setMatch('');
      setDesc('');
    });
  };

  const onChangeCommand = (e) => {
    setMatch(e.target.value);
    const allMatches = [];
    for (const cmd of commands) {
      if (Array.isArray(cmd.match)) {
        allMatches.push(...cmd.match);
      } else {
        allMatches.push(cmd.match);
      }
    }
    if (allMatches.includes(e.target.value)) {
      setError({ isError: true, helperText: 'Please enter unique command!' });
    } else {
      setError({ isError: false, helperText: '' });
    }
  };

  return (
    <div>
      <BootstrapDialog aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle>
          <Stack direction="row">
            <span>
              <Iconify icon={'flat-color-icons:command-line'} />
            </span>
            <span>{newCommandLabel}</span>
          </Stack>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Stack direction="column">
            <h4>
              {' '}
              <Iconify icon="icon-park-outline:people-speak" />
              {sayThisLabel}
            </h4>
            <TextField error={error.isError} helperText={error.helperText} value={match} onChange={onChangeCommand} />
          </Stack>
          <br />
          <Stack direction="column">
            <h4>
              <Iconify icon="fe:keyboard" />
              {toTypeThisLabel}
            </h4>
            <TextField multiline rows={4} value={desc} onChange={(e) => setDesc(e.target.value)} />
          </Stack>{' '}
          `
          <Stack direction="column">
            <FormGroup>
              <FormControlLabel
                control={<Checkbox defaultChecked size="medium" />}
                label={gptcheckLabel}
                checked={useComputer}
                onChange={() => {
                  setUseComputer(!useComputer);
                }}
              />
            </FormGroup>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button autoFocus variant="contained" onClick={doSave} disabled={disabled}>
            {loading ? <CircularProgress size={30} color="secondary" /> : saveLabel}
          </Button>
          <Button onClick={handleClose} disabled={disabled}>
            {cancelLabel}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
