// material
import {Container, Stack, Typography, Grid, Button} from "@mui/material";
// components
import { useNavigate } from "react-router-dom";
import Alert from '@mui/material/Alert';

import Page from '../components/Page';
import OnboardingSteps from "../ui-component/OnboardingSteps";
import LanguageSelector from "../ui-component/LanguageSelector";
import { useAuth } from "../context/AuthContext";
import { updateUser } from "../services/firebaseService";
import {useLanguage} from "../context/LanguageContext";

// ----------------------------------------------------------------------

export default function Permissions() {
    const { currentUser } = useAuth();
    const navigate = useNavigate()
    const { t } = useLanguage();
    const title = t('onboarding.title-2'); // Language / On-boarding
    const heading = t('onboarding.head-2'); // On-boarding / Language
    const languageInfoLabel = t('languageInfoLabel'); //  You can always change the language from settings later.
    const labelContinue = t('labelContinue'); // Continue

    const onLanguageChange = async (language) => {
        await updateUser(currentUser.email, {languageLabel: language.label, languageLocale: language.value});
    }
    const moveNext = () => {
        navigate('/onboard/congratulations')
    }

    return (
    <Page title={title}>
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
            {heading}
        </Typography>
        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" >
          <OnboardingSteps activeStep={1}/>
        </Stack>
      </Container>
      <br />
      <br />
      <br />
      <Grid container>
        <Grid item xs={4} />
        <Grid item xs={4}><LanguageSelector onChange={onLanguageChange}/> </Grid>
        <Grid item xs={4} />
      </Grid>
        <br/>
        <Grid container>
            <Grid item xs={4} />
            <Grid item xs={4}> <Button onClick={moveNext} style={{width: '100%', height: '3rem'}} variant="contained">{labelContinue}</Button> </Grid>
            <Grid item xs={4} />
        </Grid>
      <br />
      <br />
      <Container>
        <Alert severity='info'>
          <b>Note:</b> {languageInfoLabel}
        </Alert>
      </Container>
    </Page>
    );
}
