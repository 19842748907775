// mui
import { Container, Stack, Typography } from '@mui/material';
import { IconBrandYoutube } from '@tabler/icons';
import Iconify from '../components/Iconify';
import PayWithPaypal from '../ui-component/PayWithPaypal'
import PremiumBulletPoints from '../ui-component/PremiumBulletPoints'


// components
import Page from '../components/Page';
import { useLanguage } from '../context/LanguageContext';
import Faq from '../components/Faq';
import constants from '../constants';


// ----------------------------------------------------------------------

export default function Downloads() {
const { t } = useLanguage()
const help = 'Payment'; // Payment
  return (
     <Page title={help}>
     <Container>
        <h2>Make a one-time payment to unlock all features.</h2>
        <PremiumBulletPoints />
        <br />
        <PayWithPaypal />
     </Container>
     </Page>
  );
}
