import { useEffect } from 'react';
import { Container } from '@mui/material';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { createOrUpdateEntry } from '../services/firebaseService';
import { useAuth } from '../context/AuthContext';
// components
import Page from '../components/Page';

export default function Success() {
  const { currentUser } = useAuth();
  const init = async () => {
    await createOrUpdateEntry('users', currentUser.email, true);
    await fetch(`https://webhook.fxnoob.workers.dev/new-premium-account?type=1&e=${currentUser.email}`);
    window.location.href = 'https://voicetyping.xyz/';
  };
  useEffect(() => {
    init().catch((e) => {
      window.location.href = 'https://voicetyping.xyz/';
    });
  }, []);
  return (
    <Page title="Payment Successful">
      <Container style={{textAlign: 'center'}}>
          <div style={{ minHeight: '440px', textAlign: 'center', paddingTop: '2rem' }}>
            <Alert severity="success">
              <AlertTitle>Your payment was successful.</AlertTitle>
              Now you have unlocked all the premium features. <strong>Check it out!</strong>
            </Alert>
          </div>
      </Container>
    </Page>
  );
}
