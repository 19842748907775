import { useState } from 'react';
// @mui
import {
    Container, Select, MenuItem, Checkbox,
    Divider, Alert
} from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';

// components
import Page from '../components/Page';
// sections
import { useLanguage } from '../context/LanguageContext';
import { useAuth } from '../context/AuthContext';
import languages from '../services/languages'
import {updateUser} from "../services/firebaseService";
import {useDispatch, useSelector} from "react-redux";
import * as actions from "../store/actions";
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
export default function Settings() {
  const { t } = useLanguage();
  const { currentUser } = useAuth();
  const dispatch = useDispatch();
  const settingTitle = t('setting'); // Settings
  const languageSelectionLabel = t('settings.langSelect'); // What language do you speak ?
  const languageTranslation = t('st.translation') || 'Auto-Translate spoken language into another language'; // Auto-Translate spoken language into another language
  const labelSelectTargetLanguage = t('st.targetLanguage'); // Select target language
  const [lang, setLang] = useState('Afrikaans (Suid-Afrika)');
  const preferredLangs = useSelector((state) => state.pLanguage.data);
  const doTranslate = useSelector((state) => state.pLanguage.translate);
  const targetLang = useSelector((state) => state.pLanguage.targetLanguage);

  const handleLanguageSelect = (e) => {
    setLang(e.target.value)
    if (!preferredLangs.includes(e.target.value)) {
       const updatedLangs = [e.target.value, ...preferredLangs]
       dispatch({type: actions.PREFERRED_LANGUAGE_CHANGE_SUCCESS, data: updatedLangs})
       updateUser(currentUser.email, {preferredLang: updatedLangs})
    }
  }
  const handleTargetLanguageSelect = (e) => {
    if (doTranslate) {
        dispatch({type: actions.TARGET_LANGUAGE_CHANGE_SUCCESS, targetLanguage: e.target.value})
        updateUser(currentUser.email, {targetLanguage: e.target.value})
            .then(() => {
                window.location.reload()
            })
    }
  }
  const handleDelete = (chipToDelete) => () => {
    const updatedLangs = preferredLangs.filter((chip) => chip !== chipToDelete);
    dispatch({type: actions.PREFERRED_LANGUAGE_CHANGE_SUCCESS, data: updatedLangs})
    updateUser(currentUser.email, {preferredLang: updatedLangs})
  };

  const changeDoTranslate = (e) => {
    updateUser(currentUser.email, {translate: !doTranslate})
    dispatch({type: actions.TARGET_LANGUAGE_STATUS_CHANGE_SUCCESS, translate: !doTranslate})
  }

  return (
    <Page title={settingTitle}>
        <Container>
            <List
              style={{listStyleType: 'circle'}}
              sx={{ width: '100%', maxWidth: 360 }}
            >
              <ListItem>
                <ListItemText primary= {languageSelectionLabel} />
            </ListItem>
            <ListItem>
                <Select
                  onChange={handleLanguageSelect}
                  value={lang}
                  label="Select Language"
                  style={{width: '200px'}}
                >
                    {Object.keys(languages).map((langName) => (
                        <MenuItem key={langName} value={langName}>
                            {langName}
                        </MenuItem>
                      ))}
                </Select>
            </ListItem>
            <ListItem>
                <Paper
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    listStyle: 'none',
                    flexDirection: 'column',
                    p: 0.5,
                    m: 0,
                  }}
                  component="ul"
                >
                  {preferredLangs.map((data) => {
                    return (
                      <ListItem key={data}>
                        <Chip
                          label={data}
                          onDelete={handleDelete(data)}
                        />
                      </ListItem>
                    );
                  })}
                </Paper>
            </ListItem>
            <Divider />
            <ListItem>
                <br />
                <p>{languageTranslation}</p>
                <Checkbox onChange={changeDoTranslate} checked={doTranslate}></Checkbox>
            </ListItem>
                {doTranslate && <ListItem>
                    <Select
                        onChange={handleTargetLanguageSelect}
                        value={targetLang || labelSelectTargetLanguage}
                        label="Select Language"
                        style={{width: '200px'}}
                    >
                        <MenuItem key={labelSelectTargetLanguage} value={labelSelectTargetLanguage} selected disabled>
                            {labelSelectTargetLanguage}
                        </MenuItem>
                        {Object.keys(languages).map((langName) => (
                            <MenuItem key={langName} value={langName}>
                                {langName}
                            </MenuItem>
                        ))}
                    </Select>
                </ListItem>}
                {doTranslate && <ListItem>
                    <Alert severity="warning">This is an experimental feature, and bit slow to work.</Alert>
                </ListItem>}
            </List>
        </Container>
    </Page>
  );
}
