import * as actionTypes from './actions';

export const initialState = {
    initial: true,
    loading: false,
    error: null,
    translate: false,
    targetLanguage: null,
    data: [],
};

// ===========================|| Language REDUCER ||=========================== //

// eslint-disable-next-line default-param-last
const preferredLanguageReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.PREFERRED_LANGUAGE_CHANGE_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.PREFERRED_LANGUAGE_CHANGE_SUCCESS:
            console.log({action})
            return {
                ...state,
                loading: false,
                error: null,
                data: action.data,
                initial: false
            };
        case actionTypes.PREFERRED_LANGUAGE_CHANGE_FAIL:
            return {
                ...state,
                error: true,
            };
        case actionTypes.PREFERRED_LANGUAGE_CHANGE_CLEAR:
            return initialState;
        case actionTypes.TARGET_LANGUAGE_CHANGE_SUCCESS:
            return {
                ...state,
                targetLanguage: action.targetLanguage,
                error: true,
            };
        case actionTypes.TARGET_LANGUAGE_STATUS_CHANGE_SUCCESS:
            return {
                ...state,
                translate: action.translate,
                error: true,
            };
        default:
            return state;
    }
};

export default preferredLanguageReducer;
