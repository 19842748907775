// component
import Iconify from '../../components/Iconify';
import {useLanguage} from "../../context/LanguageContext";

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

export default function NavConfig() {
  const { t } = useLanguage();

  const homeTitle = t('home.title'); // Dashboard
  const commandsTitle = t('commands.title'); // Commands
  const settings  = t('settings') // Settings
  const downloads = t('addMoreExt'); // Add more extensions
  const help = t('help'); // Help

  return [
    {
      title: homeTitle,
      path: '/dashboard/app',
      icon: getIcon('flat-color-icons:home'),
    },
    {
      title: commandsTitle,
      path: '/dashboard/commands',
      icon: getIcon('flat-color-icons:command-line'),
    },
    {
      title: settings,
      path: '/dashboard/settings',
      icon: getIcon('icon-park:file-settings'),
    },
    {
      title: downloads,
      path: '/dashboard/downloads',
      icon: getIcon('icon-park:download-four'),
    },
    {
      title: help,
      path: '/dashboard/help',
      icon: getIcon('icon-park:love-and-help'),
    },
  ];
}

