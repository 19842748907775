// @mui
import { useEffect, useState } from 'react';
import { Grid, Container, Typography, Button } from '@mui/material';
// components
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@mui/material/Alert';
import Page from '../components/Page';
// sections
import { AppWidgetSummary } from '../sections/@dashboard/app/index';
import { useLanguage } from '../context/LanguageContext';
import { useAuth } from '../context/AuthContext';
import * as actions from '../store/actions';
import constants from '../constants';
import Iconify from '../components/Iconify';
import StorageManager from '../services/StorageManager';
import * as StorageKeys from '../services/StorageKeys';
import HowToUsePopup from '../ui-component/HowToUsePopup';
import { getTrialInfo } from '../services/helper';
import SalesAlert from '../ui-component/SalesAlert';
// ----------------------------------------------------------------------

export default function DashboardApp() {
  const { hl, t } = useLanguage();
  const dispatch = useDispatch();
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [showTutorialPoup, setShowTutorialPoup] = useState(false);
  const homeTitle = t('home.title'); // Dashboard
  const labelWelcomeMessage = t('home.welcomeMessage'); // Hi, Welcome back
  const labelStartTyping = t('labelStartTyping'); // Start Typing
  const labelTrialLeft = t('labelTrialLeft'); // trials left
  const homeBottomNote = t('home.bottomNote'); // Now you can automatically translate your spoken language into another language
  const setHere = t('home.setH'); // Set here
  const changeDefaultLangLabel = t('popup_default_language_tooltip_str');
  const labelGoPremium = t('labelGoPremium'); // Go Premium
  const labelLoading = t('loading'); // Loading
  const trialEnd = t('trialEnd'); // Trial Ended
  const speak = t('speak'); // Speak
  const type = t('type'); // Type
  const doTranslate = useSelector((state) => state.pLanguage.translate);
  const targetLang = useSelector((state) => state.pLanguage.targetLanguage);
  const voiceStarted = useSelector((state) => state.voice.start);
  const startedListeningFor = t('listenFor'); // Listening for
  const and = t('and'); // And
  const typingInto = t('typingInto'); // Typing into
  const startedLabel = doTranslate
    ? `${startedListeningFor} <b>${hl.name}</b> ${and} ${typingInto} <b>${targetLang}</b>...`
    : `${startedListeningFor} <b>${hl.name}</b>...`;

  const [premiumLabel, setPremiumLabel] = useState(
    `${constants.freeLimit - parseInt(currentUser?.usedCount, 10)} ${labelTrialLeft}`
  );
  const [trialOver, setTrialOver] = useState(false);


  useEffect(() => {
    const data = StorageManager.get(StorageKeys.SHOW_HOW_TO_USE_POPUP);
    if (data !== 'DONE') {
      setShowTutorialPoup(true);
      StorageManager.put(StorageKeys.SHOW_HOW_TO_USE_POPUP, 'DONE');
    }
    const { isTrial } = getTrialInfo(currentUser?.createdAt);
    if (!isTrial) {
      setTrialOver(true);
      setPremiumLabel(`${trialEnd}.`);
    }
  }, []);

  const onClickMic = (e) => {
    navigate('/dashboard/app/started');
  };
  const onClickGoPremium = e => {
    navigate('/dashboard/payment');
  };
  const onLanguageClick = () => {
    dispatch({ type: actions.LANGUAGE_CHANGE_START });
  };

  const onTranslateLanguageClick = () => {
    navigate('/dashboard/settings');
  };
  return (
    <>
      {showTutorialPoup && <HowToUsePopup isOpen={showTutorialPoup} />}
      <Page title={homeTitle}>
        <SalesAlert />
        <Container maxWidth="xl">
          <Typography
            variant="h4"
            sx={{ mb: 5 }}
            dangerouslySetInnerHTML={{ __html: voiceStarted ? startedLabel : labelWelcomeMessage }}
          ></Typography>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                tooltip={voiceStarted ? startedLabel : labelStartTyping}
                title={voiceStarted ? startedLabel : labelStartTyping}
                color="info"
                icon={'emojione-monotone:studio-microphone'}
                onClick={onClickMic}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                tooltip={changeDefaultLangLabel}
                subIcon={'mdi:speaking'}
                title={`${speak}: ${hl?.name}`}
                color="warning"
                icon={'fa-solid:language'}
                onClick={onLanguageClick}
              />
            </Grid>
            {doTranslate && targetLang && (
              <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary
                  tooltip={changeDefaultLangLabel}
                  title={`${type}: ${targetLang}`}
                  color="warning"
                  icon={'fa-solid:language'}
                  subIcon={'mdi:chat-typing'}
                  onClick={onTranslateLanguageClick}
                />
              </Grid>
            )}
            {!currentUser.paid && (
              <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary
                  tooltip={labelGoPremium}
                  title={premiumLabel}
                  color={trialOver ? 'error' : 'info'}
                  icon={'ic:sharp-workspace-premium'}
                  onClick={onClickGoPremium}
                />
              </Grid>
            )}
          </Grid>
          <br />
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12}>
              <Alert
                style={{ display: 'flex', alignItems: 'center' }}
                variant="outlined"
                severity="info"
                icon={<Iconify icon="fluent-emoji-flat:new-button" />}
              >
                {homeBottomNote}{' '}
                <Button
                  rel="noopener noreferrer"
                  target="_blank"
                  onClick={onTranslateLanguageClick}
                  style={{ textDecoration: 'none' }}
                >
                  <b>{setHere}</b>
                </Button>
                .
              </Alert>
            </Grid>
          </Grid>
        </Container>
      </Page>
    </>
  );
}
