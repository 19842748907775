// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';

// LANGUAGE_CHANGE reducer
export const LANGUAGE_CHANGE_START = 'LANGUAGE_CHANGE_START';
export const LANGUAGE_CHANGE_SUCCESS = 'LANGUAGE_CHANGE_SUCCESS';
export const LANGUAGE_CHANGE_FAIL = 'LANGUAGE_CHANGE_FAIL';
export const LANGUAGE_CHANGE_CLEAR = 'LANGUAGE_CHANGE_CLEAR';

// MIC reducer
export const MIC_NOTE_SHOW = 'MIC_NOTE_SHOW';
export const MIC_NOTE_HIDE = 'MIC_NOTE_HIDE';

// PREFERRED LANGUAGE_CHANGE reducer
export const PREFERRED_LANGUAGE_CHANGE_START = 'PREFERRED_LANGUAGE_CHANGE_START';
export const PREFERRED_LANGUAGE_CHANGE_SUCCESS = 'PREFERRED_LANGUAGE_CHANGE_SUCCESS';
export const PREFERRED_LANGUAGE_CHANGE_FAIL = 'PREFERRED_LANGUAGE_CHANGE_FAIL';
export const PREFERRED_LANGUAGE_CHANGE_CLEAR = 'PREFERRED_LANGUAGE_CHANGE_CLEAR';

export const TARGET_LANGUAGE_CHANGE_SUCCESS = 'TARGET_LANGUAGE_CHANGE_SUCCESS';
export const TARGET_LANGUAGE_STATUS_CHANGE_SUCCESS = 'TARGET_LANGUAGE_STATUS_CHANGE_SUCCESS';

// Voice Typing starts/stops/clears
export const VOICE_TYPING_START = 'VOICE_TYPING_START';
export const VOICE_TYPING_FAIL = 'VOICE_TYPING_FAIL';
export const VOICE_TYPING_STOP = 'VOICE_TYPING_STOP';
export const VOICE_TYPING_CLEAR = 'VOICE_TYPING_CLEAR';
