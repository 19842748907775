// mui
import { Container, Stack, Typography } from '@mui/material';
import { IconBrandYoutube } from '@tabler/icons';
import Iconify from '../components/Iconify';

// components
import Page from '../components/Page';
import { useLanguage } from '../context/LanguageContext';
import Faq from '../components/Faq';
import constants from '../constants';


// ----------------------------------------------------------------------

export default function Downloads() {
const { t } = useLanguage()
const help = t('help'); // Help
  return (
     <Page title={help}>
     <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {help}
          </Typography>
        </Stack>
        <Faq />
        <br />
         <Typography variant="p" gutterBottom>
            Watch our tutorial video on <a href={constants.tutorial} target="_blank"><Iconify icon="logos:youtube" /></a>. For any assistance please contact at <a href="mailto:voicetyping.xyz@gmail.com">voicetyping.xyz@gmail.com</a>.
             {" "}Read Our <a href="https://blog.voicetyping.xyz/">Blogs</a>
          </Typography>
     </Container>
     </Page>
  );
}
