import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import AddCommentIcon from '@mui/icons-material/AddComment';
import InvertColorsIcon from '@mui/icons-material/InvertColors';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import constants from '../constants';
import { useLanguage } from "../context/LanguageContext";

export default function PremiumBulletPoints() {
    const { t } = useLanguage();
    const labelForever = t('forever'); // forever
    const labelAlways = t('always'); // always
    const labelOnly = t('only'); // only
    const labelForALifetime = t('labelForALifetime'); // for a lifetime.
    const p1 = t('pbp.p1'); // Create custom commands.
    const p5 = t('pbp.p5'); // Use ChatGPT AI as a command.
    const p2 = t('pbp.p2'); // This is the only chrome manifest v3 extension for Voice Typing.
    const p3 = t('pbp.p3'); // Unlimited Use of Voice Typing.
    const p4 = `${labelOnly} ${constants.premium.pricing.mini} ${labelForALifetime}`;
    return (
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            <ListItem>
                <ListItemAvatar>
                    <Avatar>
                        <AddCommentIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={p1} secondary={labelForever} />
            </ListItem>
            <ListItem>
                <ListItemAvatar>
                    <Avatar>
                        <LibraryAddCheckIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={p5} secondary={labelAlways} />
            </ListItem>
            <ListItem>
                <ListItemAvatar>
                    <Avatar>
                        <LibraryAddCheckIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={p2} secondary={labelAlways} />
            </ListItem>
            <ListItem>
                <ListItemAvatar>
                    <Avatar>
                        <KeyboardVoiceIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={p3} secondary={labelAlways} />
            </ListItem>
            <ListItem>
                <ListItemAvatar>
                    <Avatar>
                        <InvertColorsIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={p4} secondary={labelAlways} />
            </ListItem>
        </List>
    );
}
