import React, { createContext, useEffect, useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Loader from '../ui-component/Loader';

import { app, checkEntry } from '../services/firebaseService';
import { getVoiceTypingStatus } from "../services/helper";
import StorageManager from '../services/StorageManager';
import { USER_LANGUAGE } from '../services/StorageKeys';
import * as actions from "../store/actions";

export const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [pending, setPending] = useState(true);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const unsubscribe = app.auth().onAuthStateChanged(async (user) => {
            if (user) {
                const {
                 isPaid, languageLocale, usedCount,
                 languageLabel, commands,
                 created, preferredLang, onboarded,
                 langaugeName, translate, targetLanguage
                } = await checkEntry('users', user.email);
                user.paid = isPaid;
                user.locale = languageLocale;
                user.languageLocale = languageLocale;
                user.languageName = langaugeName;
                user.languageLabel = languageLabel;
                user.usedCount = parseInt(usedCount, 10) || 0;
                user.commands = commands;
                user.createdAt = created;
                user.preferredLang = preferredLang;
                user.onboarded = onboarded;
                user.translate = translate;
                user.targetLanguage = targetLanguage;
                dispatch({type: actions.PREFERRED_LANGUAGE_CHANGE_SUCCESS, data: preferredLang})
                dispatch({type: actions.TARGET_LANGUAGE_STATUS_CHANGE_SUCCESS, translate: translate})
                dispatch({type: actions.TARGET_LANGUAGE_CHANGE_SUCCESS, targetLanguage: targetLanguage})
                StorageManager.put(USER_LANGUAGE, user.locale);
                const { message, hasCapability } = await getVoiceTypingStatus();
                if (!hasCapability) {
                    alert(message);
                } else if (!user.onboarded) {
                    navigate('/onboard/permission');
                }
            }
            setCurrentUser(user);
            setPending(false);
        });
        return unsubscribe
    }, []);

    if (pending) {
        return <Loader />;
    }

    return (
        <AuthContext.Provider
            value={{
                currentUser
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
