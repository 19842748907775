import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { IconMedal } from '@tabler/icons';
import Alert from '@mui/material/Alert';
import Chip from '@mui/material/Chip';
import PremiumBulletPoints from './PremiumBulletPoints';
import { useAuth } from '../context/AuthContext';
import { useLanguage } from '../context/LanguageContext';
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function AlertForPremium({ open, onClose, error }) {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [loading, setLoading] = React.useState(false);
  const labelGoPremium = t('labelGoPremium'); // Go Premium
  const labelCancel = t('cancel'); // cancel
  const labelJoinPremium = t('joinPremium'); // Join Premium
  const labelDiscount = t('discount'); // discount

  const handleClose = () => {
    onClose();
  };
  const makePayment = () => {
    navigate('/dashboard/payment');
    onClose();
  };

  return (
    <div>
      <BootstrapDialog aria-labelledby="customized-dialog-title" open={open}>
        {error && (
          <Alert severity="error">
            <b>{error}</b>
          </Alert>
        )}
        <DialogTitle>
          <h2>
            <IconMedal />
            <span>{labelJoinPremium} </span>
            <Chip label={`  90% ${labelDiscount}`} color="secondary" />
          </h2>
        </DialogTitle>
        <DialogContent dividers>
          <PremiumBulletPoints />
        </DialogContent>
        <DialogActions>
          {loading ? (
            <CircularProgress style={{ width: '30px', height: '30px' }} />
          ) : (
            <Button autoFocus variant="contained" onClick={makePayment}>
              {labelGoPremium}
            </Button>
          )}
          <Button onClick={handleClose}>{labelCancel}</Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
