export async function getVertexAIResponse(text) {
  try {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    const raw = JSON.stringify({
      text: text,
    });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    const response = await fetch('https://api.voicetyping.xyz/vertex', requestOptions);
    const data = await response.json();
    if (data.error) {
      throw new Error(data.error.message);
    }
    return data.candidates && Array.isArray(data.candidates) ? data.candidates[0]?.output : null;
  } catch (e) {}
  return text;
}
