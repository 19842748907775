import * as actionTypes from './actions';

export const initialState = {
  initial: true,
  start: false,
  error: null,
};

// ===========================|| Language REDUCER ||=========================== //

// eslint-disable-next-line default-param-last
const voicetypingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.VOICE_TYPING_START:
      return {
        ...state,
        initial: false,
        error: null,
        start: true,
      };
    case actionTypes.VOICE_TYPING_STOP:
      return {
        ...state,
        start: false,
        error: null,
        initial: false,
      };
    case actionTypes.VOICE_TYPING_FAIL:
      return {
        ...state,
        initial: false,
        error: null,
        start: false,
      };
    case actionTypes.VOICE_TYPING_CLEAR:
      return initialState;
    default:
      return state;
  }
};

export default voicetypingReducer;
