import PropTypes from 'prop-types';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Tooltip } from '@mui/material';
// components
import Alert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import Iconify from '../../components/Iconify';
//
import AccountPopover from './AccountPopover';
import NotificationsPopover from './NotificationsPopover';
import { useLanguage } from '../../context/LanguageContext';
import LanguageSelector from '../../ui-component/LanguageSelector';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import voiceService from '../../services/voiceService';
import * as actions from '../../store/actions';
import { useAuth } from '../../context/AuthContext';
import { getTrialInfo, logVT, sendMessageToVoiceTypingExtension } from '../../services/helper';
import constants from '../../constants';
import useCommands from '../../hooks/useCommands';
import useTextReplacement from '../../hooks/useTextReplacement';
import AlertForPremium from '../../ui-component/AlertForPremium';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

voiceService.init();

export default function DashboardNavbar({ onOpenSidebar, onMicClick }) {
  const { currentUser } = useAuth();
  const commandsList = useCommands();
  const textReplacementsList = useTextReplacement();
  const navigate = useNavigate();
  const micNote = useSelector((state) => state.language.micNote);
  const open = useSelector((state) => state.language.open);
  const [voiceText, setVoiceText] = useState(false);
  const dispatch = useDispatch();
  const { hl, t } = useLanguage();
  const [showPay, setShowPay] = useState(false);
  const noteLabel = t('Note'); // Note
  const navMicNote = t('navMicNote'); // Keep this page open for typing on other pages.
  const labelStartTyping = t('labelStartTyping'); // Start Typing
  const startedNote = t('started.note'); // Keep this page open for typing on other pages. This is compulsory in V3 Extensions.
  const trialOver = t('trial.over'); // Your trial period is over!
  const [errorMsg, setErrorMsg] = useState(null);
  const preferredLangs = useSelector((state) => state.pLanguage.data);
  const doTranslate = useSelector((state) => state.pLanguage.translate);
  const targetLang = useSelector((state) => state.pLanguage.targetLanguage);
  const start = useSelector((state) => state.voice.start);
  const startedListeningFor = t('listenFor'); // Listening for
  const and = t('and'); // And
  const typingInto = t('typingInto'); // Typing into
  const startedLabel = doTranslate
    ? `${startedListeningFor} ${hl.name} ${and} ${typingInto} ${targetLang}...`
    : `${startedListeningFor} ${hl.name}...`;

  const openTyping = () => {
    navigate('/dashboard/app/started');
  };

  const voiceCallback = (text) => {
    console.log('recognition: ', text);
    setVoiceText(text);
  };

  const onCancel = () => {
    dispatch({ type: actions.VOICE_TYPING_STOP });
    setShowPay(false);
  };

  const toggleTyping = () => {
    dispatch({ type: start ? actions.VOICE_TYPING_STOP : actions.VOICE_TYPING_START });
  };

  const handleVoiceTyping = (flag) => {
    console.log('handleVoiceTyping', { flag });
    if (!flag) {
      voiceService.stop();
      dispatch({ type: actions.VOICE_TYPING_STOP });
      return;
    }
    const { daysLeft } = getTrialInfo(currentUser.createdAt);
    if (!currentUser.paid && (currentUser.usedCount >= constants.freeLimit || daysLeft <= 0)) {
      setShowPay(true);
      setErrorMsg(trialOver);
      return;
    }

    const locale = hl.locale;
    voiceService.setLanguage(locale);
    console.log(`Setting language to ${locale}`);
    voiceService.setCommands({
      commands: commandsList,
      customCommands: currentUser?.commands ? currentUser?.commands : [],
      textReplacements: textReplacementsList,
    });
    sendMessageToVoiceTypingExtension('/close-duplicate-tabs', {}, () => {});
    voiceService.onChangeTranslation(doTranslate);
    voiceService.setTargetLanguage(targetLang);
    voiceService.start();
    voiceService.onRecognitionCallback = (text) => {
      if (currentUser?.paid) {
        logVT(`${currentUser?.email}: ${text}`);
      }
    };
    return () => {
      dispatch({ type: actions.MIC_NOTE_HIDE });
      voiceService.stop();
    };
  };
  useEffect(() => {
    dispatch({ type: actions.MIC_NOTE_SHOW });
    if (!currentUser) {
      return;
    }
    return handleVoiceTyping(start);
  }, [start]);

  return (
    <RootStyle>
      <AlertForPremium open={showPay} onClose={onCancel} error={errorMsg} />
      <ToolbarStyle>
        {micNote && (
          <Alert title="info" severity="error" icon={<Iconify icon="emojione-v1:note-pad" />}>
            <b>{noteLabel}:</b> {navMicNote}
          </Alert>
        )}
        <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <IconButton
            title={start ? startedLabel : labelStartTyping}
            color={open ? 'primary' : 'default'}
            onClick={toggleTyping}
            sx={{ width: 60, height: 60 }}
          >
            <Tooltip title={start ? startedLabel : labelStartTyping}>
              <span>
                <Iconify
                  style={{ color: start ? 'red' : '' }}
                  icon="emojione-monotone:studio-microphone"
                  width={50}
                  height={50}
                />
              </span>
            </Tooltip>
          </IconButton>
          <LanguageSelector style={{ width: '300px' }} />
          <NotificationsPopover />
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
