import { useLanguage } from "../../context/LanguageContext";
import { sendMessageToVoiceTypingExtension } from "../../services/helper";

export default () => {
  const { t } = useLanguage();

  const alias0 = t("F95EEFFE_A9EC_A42B_ED51_3B5947C00947"); // undo
  const alias1 = t("72997E36_0DC6_71D1_8336_47562155ED79"); // redo
  const alias2 = t("56287642_9B4E_5616_ACCF_1B0E03F6A2EB"); // undo all
  const description = t("F16BC2BB_AD3E_749E_683B_560DF6F914E5"); // Say undo/redo/undo all to do undo/redo/undo all.

  return {
    id: "2B16E45E_BEFD_C1FC_3173_CB814F949E1C",
    isNew: false,
    type: "frontend",
    name: "2B16E45E_BEFD_C1FC_3173_CB814F949E1C",
    description,
    condition: "exact",
    match: [alias0, alias1, alias2],
    exec: async (text, options, callback) => {
      // write your logic here.
      const { originalText } = options;
      try {
        if (originalText === alias0) {
          // undo
          sendMessageToVoiceTypingExtension('/command', {type: 'UNDO', text, options});
        } else if (originalText === alias1) {
          // redo
          sendMessageToVoiceTypingExtension('/command', {type: 'REDO', text, options});
        } else if (originalText === alias2) {
          // undo all
          sendMessageToVoiceTypingExtension('/command', {type: 'UNDO_ALL', text, options});
        }
        callback();
      } catch (e) {
        // eslint-disable-next-line
      }
    }
  };
};
