import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Alert from '@mui/material/Alert';
import { useLanguage } from '../context/LanguageContext';
import { CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { isExtensionInstalled } from '../services/helper';
import constants from '../constants';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function PageWorkingAlert({ onClose, error }) {
  const { t } = useLanguage();
  const [open, setOpen] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const labelGoPremium = t('gotit'); // Got it
  const labelJoinPremium = t('labelImpMsg'); // Important message
  const labelNotice = t('started.note'); //
  const downloadMsg = t('downloadMsg'); // Download our chrome extension from chrome extension store to type on other pages.
  const labelDownload = t('download'); // Download
  const [extInstalled, setExtInstalled] = useState(true);

  useEffect(() => {
    console.log('useEffect');
    isExtensionInstalled().then((installed) => {
      console.log({ installed });
      setExtInstalled(installed);
      setOpen(true);
    });
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleContinue = () => {
    setOpen(false);
    if (extInstalled) {
      return;
    }
    window.location.href = constants.extension.voice_typing.chrome.url;
  };

  return (
    <div>
      <BootstrapDialog aria-labelledby="customized-dialog-title" open={open}>
        <Alert severity="info">
          <span>{labelJoinPremium} </span>
        </Alert>
        <DialogContent dividers>{extInstalled ? labelNotice : downloadMsg}</DialogContent>
        <DialogActions>
          {loading ? (
            <CircularProgress style={{ width: '30px', height: '30px' }} />
          ) : (
            <Button autoFocus variant="contained" onClick={handleContinue}>
              {extInstalled ? labelGoPremium : labelDownload}
            </Button>
          )}
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
