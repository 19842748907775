import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { Button } from '@mui/material';
import constants from '../../constants';

const stats = [
  {
    label: 'Happy Users',
    value: '500K+',
  },
  {
    label: 'Speech Accuracy',
    value: '95%',
  },
  {
    label: 'Languages Supported',
    value: '100+',
  },
]

const ExpItem = ({ item }) => {
  const { value, label } = item
  return (
    <Box sx={{ textAlign: 'center', mb: { xs: 1, md: 0 } }}>
      <Typography
        sx={{ color: 'secondary.main', mb: { xs: 1, md: 2 }, fontSize: { xs: 34, md: 44 }, fontWeight: 'bold' }}
      >
        {value}
      </Typography>
      <Typography color="text.secondary" variant="h5">
        {label}
      </Typography>
    </Box>
  )
}
const StartedHero = () => {
  return (
    <Box id="hero" sx={{ backgroundColor: 'background.paper', position: 'relative', pt: 4, pb: { xs: 8, md: 10 } }}>
      <Container maxWidth="lg">
        <Grid container spacing={0} sx={{ flexDirection: { xs: 'column', md: 'unset' } }}>
          <Grid item xs={12} md={7}>
            <Box
              sx={{
                textAlign: { xs: 'center', md: 'left' },
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Box sx={{ mb: 3 }}>
                <Typography
                  component="h2"
                  sx={{
                    position: 'relative',
                    fontSize: { xs: 40, md: 72 },
                    letterSpacing: 1.5,
                    fontWeight: 'bold',
                    lineHeight: 1.3,
                  }}
                >
                  <Typography
                    component="mark"
                    sx={{
                      position: 'relative',
                      color: 'primary.main',
                      fontSize: 'inherit',
                      fontWeight: 'inherit',
                      backgroundColor: 'unset',
                    }}
                  >
                    Effortless Voice Typing {' '}
                  </Typography>
                </Typography>
              </Box>
              <Box sx={{ mb: 4, width: { xs: '100%', md: '70%' } }}>
                <Typography sx={{ color: 'text.secondary', lineHeight: 1.6 }}>
                  {
                    "Voice Typing revolutionizes the way you type text and emojis in the browser. Speak, don't type, in Gmail, Classroom, customer tickets, chats and any site with a text box. Recognizes multiple languages and works with Google Docs, Facebook Messenger, Instagram, Gmail, Wordpress, and Slack. Simply press the microphone icon and start speaking."
                  }
                </Typography>
              </Box>
              <Box sx={{ '& a': { mr: 2 } }}>
                <Button color="primary" size="large" variant="contained" href="https://voicetyping.xyz/login">
                    Get Started
                  </Button>
                <Button color="primary" size="large" variant="outlined" href={constants.tutorial}>
                    Watch Video
                  </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={5} sx={{ position: 'relative' }}>
            {/* Sertificate badge */}
            <Box sx={{ lineHeight: 0 }}>
              {/*<img src="/images/home-hero.jpg" width={775} height={787} alt="Hero img" />*/}
            </Box>
          </Grid>
        </Grid>
        <br />
        {/* Experience */}
        <Box sx={{ boxShadow: 2, py: 4, px: 7, borderRadius: 4 }}>
          <Grid container spacing={2}>
            {stats.map((item) => (
              <Grid key={item.value} item xs={12} md={4}>
                <ExpItem item={item} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  )
}
export default StartedHero;
