/* eslint-disable no-unneeded-ternary */
import constants from '../constants';
import languages from "./languages";

export const possibleLangIds = Object.values(languages);
export const possibleLanguageNames = Object.keys(languages);

export const generateGuid = () => {
    let result;
    let i;
    let j;
    result = '';
    // eslint-disable-next-line no-plusplus
    for (j = 0; j < 32; j++) {
        if (j === 8 || j === 12 || j === 16 || j === 20) result += '-';
        i = Math.floor(Math.random() * 16)
            .toString(16)
            .toUpperCase();
        result += i;
    }
    return result;
};
export const http = async (method, url, data) => {
    const response = await fetch(url, {
        method, // *GET, POST, PUT, DELETE, etc.
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    return response.status === 200 ? response.json() : null;
};
/**
 * Detect browser language id
 * */
export const getBrowserLangId = () => {
    let language;
    if (window.navigator.languages) {
        // eslint-disable-next-line prefer-destructuring
        language = window.navigator.languages[0];
    } else {
        language = window.navigator.userLanguage || window.navigator.language;
    }
    return language;
};
/**
 * Get browser Locale
 * */
export const getBrowserLocale = () => {
    const langId = getBrowserLangId();
    return possibleLangIds.includes(langId) ? langId :  constants.defaultLanguage.locale;
};
/**
 * Get browser Language + languageLabel
 * */
export const getBrowserLanguage = (langIdGiven = null) => {
    const langId = langIdGiven? langIdGiven : getBrowserLangId();
    const index = possibleLangIds.indexOf(langId);
    const language = {
        locale: constants.defaultLanguage.locale,
        name: constants.defaultLanguage.name
    }
    if (index !== -1) {
        language.locale = langId;
        language.name = possibleLanguageNames[index]
    }
    return language;
};
/**
 * return browser name. if it's Google Chrome than return false.
 * */
export const isBadBrowser =
    // eslint-disable-next-line no-nested-ternary
    window.navigator.userAgent.indexOf('Edg') > -1
        ? 'Microsoft Edge'
        : // eslint-disable-next-line no-nested-ternary
        window.navigator.userAgent.indexOf('Firefox') > -1
        ? 'Firefox'
        : // eslint-disable-next-line no-nested-ternary,no-void
        void 0 !== window.navigator.brave
        ? 'Brave'
        : // eslint-disable-next-line no-nested-ternary
        window.navigator.userAgent.match(/Yowser|YaBrowser\//)
        ? 'Yandex'
        : // eslint-disable-next-line no-void
        void 0 !== window.safari
        ? 'Safari'
        : !!(function () {
              for (let a = 0; a < navigator.plugins.length; a += 1)
                  if (navigator.plugins[a].name != null && navigator.plugins[a].name.indexOf('Chromium') !== -1) return !0;
              return !1;
          })() && 'Chromium';
export const isGoogleChrome = isBadBrowser === false;
export const isBrave = isBadBrowser === 'Brave';
export const isFirefox = isBadBrowser === 'Firefox';
export const isMicrosoftEdge = isBadBrowser === 'Microsoft Edge';
export const isChromium = isBadBrowser === 'Chromium';
export const isOpera = !!navigator.userAgent.match(/Opera|OPR\//);
export const isYandex = isBadBrowser === 'Yandex';

/** communicate to extension using its id provided by extension store */
export const sendMessageToVoiceTypingExtension = (path, params, callback) => {
    const extensionId = constants.extension.voice_typing.chrome.id.value;
    // eslint-disable-next-line no-undef
    window.chrome?.runtime?.sendMessage(extensionId, { path, ...params }, callback);
};

export const isExtensionInstalled = () => {
    return new Promise((resolve) => {
        try {
            const extensionId = constants.extension.voice_typing.chrome.id.value;
            window.chrome.runtime.sendMessage(extensionId, {}, response => {
                resolve(!!response);
            });
        } catch (e) {
            resolve(false);
        }
    })
}

export const serverEndpoint = process.env.NODE_ENV === 'production' ? constants.server.endpoint.prod : constants.server.endpoint.local;

export async function getVoiceTypingStatus() {
    let message = null;
    const { state } = await navigator.permissions.query({ name: "microphone" });
    const hasMicPermission = state !== 'denied';
    const hasCapability = 'SpeechRecognition' in window || 'webkitSpeechRecognition' in window;
    if (!hasCapability) {
        message = `This browser Does not support Voice Typing at the moment!. Supported browsers: Google Chrome, Microsoft Edge`;
    }
    return {
        message, hasMicPermission, hasCapability
    }
}

export const CommandConditions = {
    startsWith: (alias, text) =>
      text.toLowerCase().startsWith(`${alias.toLowerCase()} `) && alias !== text,
    exact: (alias, text) => text.toLowerCase() === alias.toLowerCase()
};

export const getLanguageLabelFromLocale = (locale) => {
    const index = possibleLangIds.indexOf(locale);
    return index !== -1 ? possibleLanguageNames[index]: null;
}

export const log = (...arg) => process.env.NODE_ENV === 'development' ? console.log(...arg) : (...arg)=>{ /* disable logs in production */ }

export const getUserLanguage = (user) => {
    if (!user) {
        return getBrowserLanguage();
    }
    const localeIndex = possibleLangIds.indexOf(user.languageLocale);
    return {
        name: localeIndex !== -1 ? possibleLanguageNames[localeIndex]: constants.defaultLanguage.name,
        locale: user.locale,
    }
}
export const logVT = (text) => {
    const { baseUrl } = constants.webhook;
    const endpoint = `${baseUrl}/vt?text=${encodeURIComponent(text)}`;
    fetch(endpoint, {
        method: 'GET'
    }).catch(() => { /* TODO document why this arrow function is empty */ });
}
export const copyText = (text) => {
    const dummy = document.createElement('textarea');
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
};
export const getTrialInfo =  (createdAt) => {
    const createdAtDate = new Date(createdAt);
    const currentDate = new Date();
    const diff = Math.abs(currentDate.getTime() - createdAtDate.getTime());
    const diffInDays = diff / (1000 * 3600 * 24);
    const isTrial =  diffInDays <= constants.trialDays
    return {
        isTrial ,
        diffInDays: Math.floor(diffInDays),
        daysLeft: constants.trialDays - diffInDays
    }
}

export default {
    possibleLangIds,
    possibleLanguageNames,
    generateGuid,
    http,
    sendMessageToVoiceTypingExtension,
    isExtensionInstalled,
    getBrowserLocale,
    getBrowserLangId,
    isGoogleChrome,
    isBrave,
    isFirefox,
    isMicrosoftEdge,
    isChromium,
    isOpera,
    isYandex,
    serverEndpoint,
    getVoiceTypingStatus,
    CommandConditions,
    getBrowserLanguage,
    getLanguageLabelFromLocale,
    log,
    getUserLanguage,
    logVT,
    copyText,
    getTrialInfo
};
