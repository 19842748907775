// @mui
import { alpha, styled } from '@mui/material/styles';
import {Button, Card, Typography, Tooltip, Grid, ButtonGroup} from '@mui/material';
// components
import Iconify from '../../../components/Iconify';
import { useLanguage } from '../../../context/LanguageContext';
import languages from "../../../services/languages";

// ----------------------------------------------------------------------

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------
export default function AppWidgetSummary({
  onClick,
  onCancel,
  cancel,
  title,
  text,
  icon,
  color = 'primary',
  sx,
  iconWidth,
  iconHeight,
  tooltip,
  preferredLangs,
  subIcon,
  ...other
}) {
  const { t } = useLanguage();
  const stopLabel = t('stop'); // Stop
  return (
    <Card
      sx={{
        py: 5,
        boxShadow: 0,
        textAlign: 'center',
        color: (theme) => theme.palette[color].darker,
        bgcolor: (theme) => theme.palette[color].lighter,
        cursor: 'pointer',
        ...sx,
      }}
      {...other}
    >
      <IconWrapperStyle
        style={{boxShadow: '1px 1px 3px 2px rgb(69 90 100 / 45%)'}}
        onClick={onClick}
        sx={{
          width: iconWidth,
          height: iconHeight,
          color: (theme) => theme.palette[color].dark,
          backgroundImage: (theme) =>
            `linear-gradient(135deg, ${alpha(theme.palette[color].dark, 0)} 0%, ${alpha(
              theme.palette[color].dark,
              0.24
            )} 100%)`,
        }}
      >
        {tooltip ? (
          <Tooltip title={tooltip}>
            <span style={{display: 'flex', flexDirection: 'column'}}>
              <Iconify icon={icon} width={iconWidth || 24} height={iconHeight || 24} />
                {subIcon && <Iconify icon={subIcon} width={iconWidth || 24} height={iconHeight || 24}/>}
            </span>
          </Tooltip>
        ) : (
          <Iconify icon={icon} width={iconWidth || 24} height={iconHeight || 24} />
        )}
      </IconWrapperStyle>

      {text && <Typography variant="h3">{text}</Typography>}

      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        <div dangerouslySetInnerHTML={{ __html: title }} />
      </Typography>
      <br />
      <Grid>
          {preferredLangs && preferredLangs.length > 0 &&
          <>
          <ButtonGroup variant="outlined">
              {preferredLangs.map(pl => (
                <Button>
                    <a style={{textDecoration: 'none'}} href={`?hl=${languages[pl]}`}>{pl}</a>
                </Button>
              ))}
          </ButtonGroup>
          </>}
      </Grid>
      <br />
      {cancel === 'YES' && (
        <Button onClick={onCancel} variant="outlined" sx={{ opacity: 0.72 }} severity="error">
          {stopLabel}
        </Button>
      )}
    </Card>
  );
}
