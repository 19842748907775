import { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import languages from '../services/languages';
import { useLanguage } from '../context/LanguageContext';

const options = Object.keys(languages).map((name) => ({ value: languages[name], label: name }));

// eslint-disable-next-line react/prop-types
export default function LanguageSelector({ style, micNeeded }) {
  const { hl, t } = useLanguage();
  const [value, setValue] = useState(hl.name);
  const changeLanguage = async (newInputValue) => {
    if (!newInputValue) {
      return;
    }
    setValue(newInputValue);
    console.log({ newInputValue });
    window.location.href = `?hl=${newInputValue.value}`;
  };

  return (
    <>
      <Autocomplete
        style={style || {}}
        value={value}
        onChange={(event, newValue) => {
          changeLanguage(newValue);
        }}
        id="language-selector"
        options={options}
        renderInput={(params) => <TextField {...params} />}
      />
    </>
  );
}
