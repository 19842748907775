import React from 'react'
import StartedHero from './started/Home';
import DynamicHomeFeature from './started/Features';
import DynamicHomeTestimonial from './started/testimonial';
import DynamicHomeNewsLetter from './started/newsletter';
import Header from './started/header'
import Footer from './started/footer';

export default function Started(){
  return (
   <>
      <Header/>
      <StartedHero />
      <DynamicHomeFeature />
      <DynamicHomeTestimonial />
      <DynamicHomeNewsLetter />
      <Footer/>
    </>
  )
}
