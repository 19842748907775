// mui
import { Container, Stack, Typography } from '@mui/material';

// components
import Page from '../components/Page';
import Faq from '../components/Faq';
import constants from '../constants';


// ----------------------------------------------------------------------

export default function Downloads() {
const heading = 'Image to Text (OCR) / Data Deletion Instruction';
  return (
     <Page title={heading}>
     <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {heading}
          </Typography>
        </Stack>
       <section id="main-container" className="container inner">
         <div id="main-content" className="main-page clearfix">
           <div id="main" className="site-main">
             <p>According to the Facebook Platform rules, we have to provide&nbsp;<strong>User Data Deletion Callback
               URL</strong>&nbsp;or&nbsp;<strong>Data Deletion Instructions URL</strong>. If you want to delete your
               activities for <a href="https://voicetyping.xyz">voicetyping.xyz</a>, you can remove your activities by
               the following instructions.</p>
             <ol style={{marginLeft: '20px'}}>
               <li>Go to Your Facebook Account’s Setting &amp; Privacy. Click ” Setting “.</li>
               <li>Then, go to ” Apps and Websites” and you will see all of your Apps activities.</li>
               <li>Select the option box of VoiceTyping.</li>
               <li>Click ” Remove” button.</li>
               <li>Congratulation , you are successfully remove your activities</li>
             </ol>
           </div>
         </div>
       </section>
     </Container>
     </Page>
  );
}
