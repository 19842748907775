import React, { createContext, useContext, useEffect, useState } from 'react';
import Loader from '../ui-component/Loader';
import enLocale from '../scripts/locale_en.json';
import { useAuth } from "./AuthContext";
import { updateUser } from "../services/firebaseService";
import {getBrowserLanguage, log, possibleLangIds, possibleLanguageNames} from "../services/helper";

const queryString = require('query-string');

const parsed = queryString.parse(window.location.search);


const LanguageContext = createContext();

export function useLanguage() {
    return useContext(LanguageContext);
}

let localeData = {};

function t(key, defaultValue = '') {
    return localeData[key] || defaultValue;
}

export function LanguageProvider({ children }) {
    const { currentUser } = useAuth();
    const [loading, setLoading] = useState(true);
    // eslint-disable-next-line no-prototype-builtins
    const queryLocale = parsed.hl && possibleLangIds.includes(parsed.hl)? parsed.hl : null;
    let resultantLocale = queryLocale ? queryLocale : currentUser?.languageLocale;
    let resultantLangName = queryLocale ? possibleLanguageNames[possibleLangIds.indexOf(parsed.hl)] : currentUser?.languageLabel;
    if (!resultantLocale || !resultantLangName) {
        const bLang = getBrowserLanguage()
        resultantLocale = bLang.locale;
        resultantLangName = bLang.name;
    }
    const [hl, setHl] = useState({locale: resultantLocale, name: resultantLangName});
    const init = async () => {
        try {
            const hLang = hl.locale.split('-')[0];
            if (currentUser && queryLocale && currentUser?.languageLocale !== queryLocale) {
                log(`updating users language from ${currentUser?.languageLocale} to ${queryLocale}`);
                updateUser(currentUser.email, {locale: queryLocale, languageLocale: queryLocale, languageLabel: resultantLangName}).catch(e => console.log(e));
            }
            if (hLang === 'en') {
                throw new Error('language available');
            }
            const data = await fetch(`/_locales/${hLang}/messages.json`);
            localeData = await data.json();

        } catch (e) {
            localeData = enLocale;
        }
        setLoading(false);
    };
    useEffect(() => {
        init();
    }, []);
    const value = { t, hl };
    return <LanguageContext.Provider value={value}>{loading ? <Loader /> : children}</LanguageContext.Provider>;
}
