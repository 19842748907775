// @mui
import { Grid, Container, Typography, IconButton } from '@mui/material';
import CopyIcon from '@mui/icons-material/CopyAll';
// components
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../components/Page';
// sections
import { AppWidgetSummary } from '../sections/@dashboard/app/index';
import { useAuth } from '../context/AuthContext';
import AlertForPremium from '../ui-component/AlertForPremium';
import { copyText } from '../services/helper';
import useCommands from '../hooks/useCommands';
import { useLanguage } from '../context/LanguageContext';
import useTextReplacement from '../hooks/useTextReplacement';
import * as actions from '../store/actions';
import Iconify from '../components/Iconify';
import SalesAlert from '../ui-component/SalesAlert';
import PageWorkingAlert from '../ui-component/PageWorkingAlert';
// ----------------------------------------------------------------------

export default function DashboardAppStarted() {
  const [voiceText, setVoiceText] = useState(false);
  const { currentUser } = useAuth();
  const dispatch = useDispatch();
  const commandsList = useCommands();
  const textReplacementsList = [...currentUser.commands, ...useTextReplacement()];
  const navigate = useNavigate();
  const { hl, t } = useLanguage();
  const emojisList = t('emojis');
  const [showPay, setShowPay] = useState(false);
  const labelWelcomeMessage = t('home.welcomeMessage'); // Hi, Welcome back
  const startedTitle = t('started.title'); // Listening..
  const note = t('Note'); // Note
  const startedListeningFor = t('listenFor'); // Listening for
  const and = t('and'); // And
  const typingInto = t('typingInto'); // Typing into
  const startedNote = t('started.note'); // Keep this page open for typing on other pages. This is compulsory in V3 Extensions.
  const trialOver = t('trial.over'); // Your trial period is over!
  const [errorMsg, setErrorMsg] = useState(null);
  const preferredLangs = useSelector((state) => state.pLanguage.data);
  const doTranslate = useSelector((state) => state.pLanguage.translate);
  const targetLang = useSelector((state) => state.pLanguage.targetLanguage);
  const startedLabel = doTranslate
    ? `${startedListeningFor} <b>${hl.name}</b> ${and} ${typingInto} <b>${targetLang}</b>...`
    : `${startedListeningFor} <b>${hl.name}</b>...`;
  console.log(preferredLangs);
  const onCancel = () => {
    dispatch({ type: actions.VOICE_TYPING_STOP });
    navigate('/dashboard/app');
  };

  useEffect(() => {
    dispatch({ type: actions.MIC_NOTE_SHOW });
    dispatch({ type: actions.VOICE_TYPING_START });
  });

  return (
    <>
      <AlertForPremium open={showPay} onClose={onCancel} error={errorMsg} />
      <Page title={startedTitle}>
        <SalesAlert />
        <PageWorkingAlert />
        <Container maxWidth="xl">
          <Typography variant="h4" sx={{ mb: 5 }}>
            {labelWelcomeMessage}
          </Typography>
          <Grid container spacing={3} style={{ minHeight: '400px' }}>
            <Grid item xs={12} sm={12} md={12} style={{ minHeight: '400px' }}>
              <AppWidgetSummary
                style={{ minHeight: '400px' }}
                title={startedLabel}
                color="info"
                icon={'ant-design:audio-filled'}
                iconWidth={300}
                iconHeight={300}
                cancel="YES"
                preferredLangs={preferredLangs}
                onCancel={onCancel}
              />
            </Grid>
            {voiceText && (
              <Grid item xs={12} sm={12} md={12}>
                <Alert
                  icon={<Iconify icon="emojione-monotone:studio-microphone" />}
                  severity="info"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        copyText(voiceText);
                      }}
                    >
                      <CopyIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {voiceText}
                </Alert>
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={12}>
              <Alert title="info" severity="error">
                <b>{note}:</b> {startedNote}
              </Alert>
            </Grid>
          </Grid>
        </Container>
      </Page>
    </>
  );
}
