import React from 'react';

// material-ui
import LinearProgress from '@mui/material/LinearProgress';

// style constant
const styles = {
    root: {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1301,
        width: '100%'
        // '& > * + *': {
        //     marginTop: theme.spacing(2)
        // }
    }
}
// ===========================|| Loader ||=========================== //

const Loader = () => {
    return (
        <div style={styles.root}>
            <LinearProgress color="primary" />
        </div>
    );
};

export default Loader;
