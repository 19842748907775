import React, { FC } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import FooterNavigation from '../../components/footer/footer-navigation'
import FooterSocialLinks from '../../components/footer/footer-social-links'

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{ backgroundColor: '#fdf2f2', py: { xs: 6, md: 10 }, color: 'black' }}
    >
      <Container>
        <Grid container spacing={1}>
          <Grid item xs={12} md={5}>
            <Box sx={{ width: { xs: '100%', md: 360 }, mb: { xs: 3, md: 0 } }}>
              <Typography component="h2" variant="h2" sx={{ mb: 2 }}>
                VoiceTyping.xyz
              </Typography>
              <Typography variant="subtitle1" sx={{ letterSpacing: 1, mb: 2 }}>
                Our App - Voice Typing simplifies typing text and emojis in the browser with voice-to-text technology.
              </Typography>
              <FooterSocialLinks />
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <FooterNavigation />
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default Footer
