const constants = {
    tutorial: "https://www.youtube.com/watch?v=ly0PalUJ0gc",
    freeLimit: 25,
    trialDays: 7,
    freeCustomCommands: 3,
    waitTimeInMiliSeconds: 5000,
    webhook: {
        baseUrl: 'https://webhook.fxnoob.workers.dev'
    },
    extension: {
        voice_typing: {
            chrome: {
                id: {
                    dev: 'cdgeanphcpkjeeekhebhijmkkbcplfhh',
                    prod: 'hmpihaioaacpehkghnkmnmgmihalkmdf',
                    get value () {
                       return process.env.NODE_ENV === 'development'
                            ? constants.extension.voice_typing.chrome.id.dev
                            : constants.extension.voice_typing.chrome.id.prod
                    }
                },
                url: 'https://chrome.google.com/webstore/detail/voice-typing/hmpihaioaacpehkghnkmnmgmihalkmdf'
            },
            pricing: {
                priceId: {
                    dev: 'price_1LgrqrSGxsTQeml5JmpSDmQ4', // 13.5 dollars
                    prod: 'price_1L63TqSGxsTQeml5jJfczpaV', // 13 dollars
                    get value() {
                        return process.env.NODE_ENV === 'development'
                            ? constants.extension.voice_typing.pricing.priceId.dev
                            : constants.extension.voice_typing.pricing.priceId.prod;
                    }
                }
            },
            paymentCancelURL: {
                dev: 'http://localhost:3001/dashboard/app',
                prod: 'https://voicetyping.xyz/dashboard/app',
                get value() {
                    return process.env.NODE_ENV === 'development'
                        ? constants.extension.voice_typing.paymentCancelURL.dev
                        : constants.extension.voice_typing.paymentCancelURL.prod;
                }
            },
            paymentSuccessURL: {
                dev: 'http://localhost:3001/success',
                prod: 'https://voicetyping.xyz/success',
                get value() {
                    return process.env.NODE_ENV === 'development'
                        ? constants.extension.voice_typing.paymentSuccessURL.dev
                        : constants.extension.voice_typing.paymentSuccessURL.prod;
                }
            },
            stripe: {
                apiKey: {
                    dev: 'pk_test_51IyOhfSGxsTQeml5lMqwqqRlA9z0X2zWh5rRNWQB218HDOds5AfvgmEGeCbr6ItUk2J6BMEpXAaNYXX5AiqsJXY600PX7zbIZG',
                    prod: 'pk_live_51IyOhfSGxsTQeml5PtdsmRdbE3Klc2N2vkwa9Bsr0Cig3laCyZCh74Ll4Ng3szXsaWrxj1rLbedWxivbOxI4wRxC00VbrF2XAd',
                    get value() {
                        return process.env.NODE_ENV === 'development'
                            ? constants.extension.voice_typing.stripe.apiKey.dev
                            : constants.extension.voice_typing.stripe.apiKey.prod;
                    }
                }
            }
        },
    },
    premium: {
        endpoint: {
            dev: 'http://localhost:3001',
            prod: 'https://voicetyping.xyz',
            get value() {
                return process.env.NODE_ENV === 'development'
                  ? constants.extension.voice_typing.stripe.apiKey.dev
                  : constants.extension.voice_typing.stripe.apiKey.prod;
            }
        },
        pricing: {
            short: '$13',
            mini: 'INR 1050 ~ $13',
            description: 'INR 1050 ~ $13 for a lifetime.',
            full: 'INR 1050 ~ $13 for a lifetime.'
        }
    },
    support: {
        feedbackForm: 'https://forms.gle/xVpE6hfoyCrD1vAx8',
        youtube: 'https://www.youtube.com/fxnoob'
    },
    server: {
        frontEnd: {},
        endpoint: {
            local: 'http://127.0.0.1:8787',
            prod: 'https://webhook.fxnoob.workers.dev'
        }
    },
    defaultLanguage: {
        locale: 'en-US',
        name: 'English (United States)'
    }
};
export default constants;
