import { useLanguage } from "../../context/LanguageContext";
import { sendMessageToVoiceTypingExtension } from "../../services/helper";
import { getVertexAIResponse } from '../../services/google';

export default () => {
  const { t } = useLanguage();
  const commandAlias = t('computer'); // computer
  const description = t("command_computer_description");
  return {
    id: '2C813AB6-109C-7BBE-50A5-B54CE1C30B20',
    isNew: true,
    type: 'frontend',
    name: commandAlias,
    description,
    condition: "startsWith",
    match: [commandAlias],
    exec: async (text, options) => {
      try {
        const response = await getVertexAIResponse(text);
        console.log({response})
        sendMessageToVoiceTypingExtension('/command', {type: null, text: response, options});
      } catch (e) {
        console.log("Error in vertex ai", {e})
        sendMessageToVoiceTypingExtension('/set-loader', {isLoading: true, message: e.message});
        setTimeout(() => {
          sendMessageToVoiceTypingExtension('/set-loader', {isLoading: false});
        }, 4000)
      }
    }
  };
};
