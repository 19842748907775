// material
import { Button, Container, Typography } from "@mui/material";
// components
import { Link as RouterLink } from "react-router-dom";
import Alert from '@mui/material/Alert';
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'


import { styled } from "@mui/material/styles";
import Page from '../components/Page';
import {useLanguage} from "../context/LanguageContext";
import { useAuth } from '../context/AuthContext';
import { useEffect } from 'react';
import { updateUser } from '../services/firebaseService';

// ----------------------------------------------------------------------
const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(0, 0)
}));

export default function Permissions() {
  const { width, height } = useWindowSize()
  const { t } = useLanguage();
  const { currentUser } = useAuth();
  const CongoTitle = t('congo.title'); // Congratulations | On-boarding
  const CongoHeading = t('congo.heading'); // Congratulations
  const congoDesc = t('congo.desc'); // Now you can use "Voice Typing" on any website you want!
  const startVoiceTypingButton = t('congo.startVoiceTypingButton'); // Start Voice Typing
  useEffect(() => {
    if (currentUser) {
      updateUser(currentUser.email, { onboarded: true})
    }
  }, [currentUser]);
  return (
    <Page title={CongoTitle}>
      <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
        <Typography variant="h2" sx={{ mb: 5 }}>
          {CongoHeading}
        </Typography>
        <Confetti
          width={width}
          height={height}
        />
        <br />
        <br />
        <Container>
          <Alert severity='success'>
            <Typography variant="p" sx={{ mb: 5 }} style={{fontWeight: 'normal'}}>
              {congoDesc}
            </Typography>
          </Alert>
        </Container>
        <br />
        <Button to="/dashboard/app/started" size="large" variant="contained" component={RouterLink}>
          {startVoiceTypingButton}
        </Button>
      </ContentStyle>
    </Page>
  );
}
