import React, { FC, useRef } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Slider, { Settings } from 'react-slick'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import IconArrowBack from '@mui/icons-material/ArrowBack'
import IconArrowForward from '@mui/icons-material/ArrowForward'
import 'slick-carousel/slick/slick.css'
import './slider.css'
import TestimonialItem  from './testimonial-item'

const data = [
  {
    id: 1,
    title: 'Making Life Easier',
    content:
      'Voice Typing has made my life so much easier! I can now type emails, write blog posts, and send messages all while doing other things. It saves me so much time and effort.',
    user: {
      id: 1,
      name: 'Jane D.',
      professional: 'UI/UX Engineer',
      photo: '1.jpg',
    },
  },
  {
    id: 2,
    title: 'Best Language learning Tool!',
    content:
      'As a language learner, Voice Typing has been a game-changer for me. I can now practice pronunciation and type in different languages with ease.',
    user: {
      id: 1,
      name: 'Mark T.',
      professional: 'Language Learner',
      photo: '2.jpg',
    },
  },
  {
    id: 3,
    title: 'Very complete class',
    content:
      'I never thought typing could be so enjoyable, but with Voice Typing, it truly is! I can now type without having to constantly look at the keyboard and it\'s amazing.',
    user: {
      id: 1,
      name: 'Sarah P.',
      professional: 'Blogger',
      photo: '3.jpg',
    },
  },
  {
    id: 4,
    title: 'Great Quality!',
    content:
      'I work in customer service and have to type responses all day long. Voice Typing has been a lifesaver for me and has increased my productivity tenfold.',
    user: {
      id: 1,
      name: 'Diana Jordan',
      professional: 'PC User',
      photo: '4.jpg',
    },
  }
]

const SliderArrow = (props) => {
  const { onClick, type, className } = props
  return (
    <IconButton
      sx={{
        backgroundColor: 'background.paper',
        color: 'primary.main',
        '&:hover': { backgroundColor: 'primary.main', color: 'primary.contrastText' },
        bottom: { xs: '-28px !important', md: '64px !important' },
        left: 'unset !important',
        right: type === 'prev' ? '90px !important' : '30px !important',
        zIndex: 10,
        boxShadow: 1,
      }}
      disableRipple
      color="inherit"
      onClick={onClick}
      className={className}
    >
      {type === 'next' ? <IconArrowForward sx={{ fontSize: 22 }} /> : <IconArrowBack sx={{ fontSize: 22 }} />}
    </IconButton>
  )
}

const StyledSlickContainer = styled('div')(() => ({
  position: 'relative',
}))

const HomeTestimonial = () => {
  const sliderRef = useRef(null)

  const sliderConfig = {
    infinite: true,
    autoplay: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <SliderArrow type="prev" />,
    nextArrow: <SliderArrow type="next" />,
  }

  return (
    <Box id="testimonial" sx={{ pb: { xs: 6, md: 10 }, backgroundColor: 'background.paper' }}>
      <Container>
        <Grid container spacing={5}>
          <Grid item xs={12} md={6}>
            <Typography
              component="h2"
              sx={{
                position: 'relative',
                fontSize: { xs: 36, md: 46 },
                mt: { xs: 0, md: 7 },
                mb: 4,
                lineHeight: 1,
                fontWeight: 'bold',
              }}
            >

              Testimonial What our{' '}
              <Typography
                component="mark"
                sx={{
                  position: 'relative',
                  color: 'primary.main',
                  fontSize: 'inherit',
                  fontWeight: 'inherit',
                  backgroundColor: 'unset',
                }}
              >
                Users{' '}
                <Box
                  sx={{
                    position: 'absolute',
                    top: { xs: 20, md: 28 },
                    left: 2,
                    '& img': { width: { xs: 130, md: 175 }, height: 'auto' },
                  }}
                >
                  <img src="/images/headline-curve.svg" alt="Headline curve" />
                </Box>
              </Typography>
              Say
            </Typography>

            <StyledSlickContainer>
              <Slider ref={sliderRef} {...sliderConfig}>
                {data.map((item, index) => (
                  <TestimonialItem key={String(index)} item={item} />
                ))}
              </Slider>
            </StyledSlickContainer>
          </Grid>
          <Grid item xs={12} md={6} sx={{ display: { xs: 'none', md: 'block' } }}>
            <Box sx={{ width: { xs: '100%', md: '90%' } }}>
              <img src="/images/home-testimonial.png" width={520} height={540} quality={97} alt="Testimonial img" />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default HomeTestimonial
