import React  from 'react'
import Grid from '@mui/material/Grid'
import MuiLink from '@mui/material/Link'
import FooterSectionTitle from './footer-section-title';

const headerNavigations= [
  {
    label: 'Dashboard',
    path: 'https://voicetyping.xyz/dashboard/app',
  },
  {
    label: 'Commands',
    path: 'https://voicetyping.xyz/dashboard/commands',
  },
  {
    label: 'Settings',
    path: 'https://voicetyping.xyz/dashboard/settings',
  },
  {
    label: 'Downloads',
    path: 'https://voicetyping.xyz/dashboard/downloads',
  },
]

const pageMenu = headerNavigations

const companyMenu = [
  { label: 'Support', path: 'https://forms.gle/Wi9LbfKstPpwkLh89' },
  { label: 'Privacy & Policy', path: 'https://voicetyping.xyz/privacy' },
  { label: 'Term & Condition', path: 'https://voicetyping.xyz/terms' },
  { label: 'FAQ', path: 'https://voicetyping.xyz/dashboard/help' },
]

const NavigationItem = ({ label, path }) => {
  return (
    <a href={path} passHref>
      <MuiLink
        underline="hover"
        sx={{
          display: 'block',
          mb: 1,
          color: 'black',
        }}
        style={{textDecoration: 'none'}}
      >
        {label}
      </MuiLink>
    </a>
  )
}

const FooterNavigation = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <FooterSectionTitle title="Menu" />
        {pageMenu.map(({ label, path }, index) => (
          <NavigationItem key={index + path} label={label} path={path} />
        ))}
      </Grid>
      <Grid item xs={12} md={4}>
        <FooterSectionTitle title="About" />
        {companyMenu.map(({ label, path }, index) => (
          <NavigationItem key={index + path} label={label} path={path} />
        ))}
      </Grid>
    </Grid>
  )
}

export default FooterNavigation
