import * as actionTypes from './actions';

export const initialState = {
    initial: true,
    open: false,
    error: null,
    micNote: false,
};

// ===========================|| Language REDUCER ||=========================== //

// eslint-disable-next-line default-param-last
const languageReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LANGUAGE_CHANGE_START:
            return {
                ...state,
                initial: false,
                open: true
            };
        case actionTypes.LANGUAGE_CHANGE_SUCCESS:
            return {
                ...state,
                open: false,
                error: null,
                initial: false
            };
        case actionTypes.MIC_NOTE_SHOW:
            return {
                ...state,
                micNote: true,
            };
        case actionTypes.MIC_NOTE_HIDE:
            return {
                ...state,
                micNote: false,
            };
        case actionTypes.LANGUAGE_CHANGE_CLEAR:
            return initialState;
        default:
            return state;
    }
};

export default languageReducer;
