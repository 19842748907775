/* eslint-disable */
import React, { useEffect } from 'react';
import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';

// These values are the props in the UI
const amount = '13';
const currency = 'USD';
const style = { layout: 'vertical', label: 'pay' };
const clientId = 'AYhYM6QS7Csjy24L4TlW2W8H7_A9NaP9_ib4ErLIEj8L6Psn8CXnhsQk2YHweIiYPdB6LdhXnKhW2u4A';

// Custom component to wrap the PayPalButtons and handle currency changes
const ButtonWrapper = ({ currency, showSpinner }) => {
    // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
    // This is the main reason to wrap the PayPalButtons in a new component
    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

    useEffect(() => {
        dispatch({
            type: 'resetOptions',
            value: {
                ...options,
                currency
            }
        });
    }, [currency, showSpinner]);

    return (
        <>
            {showSpinner && isPending && <div className="spinner" />}
            <PayPalButtons
                style={style}
                disabled={false}
                forceReRender={[amount, currency, style]}
                fundingSource={undefined}
                createOrder={(data, actions) =>
                    actions.order
                        .create({
                            purchase_units: [
                                {
                                    amount: {
                                        currency_code: currency,
                                        value: amount
                                    }
                                }
                            ],
                            application_context: {
                                shipping_preference: 'NO_SHIPPING',
                                user_action: 'PAY_NOW'
                            },
                            payer: {
                                email_address: 'customer@example.com'
                            }
                        })
                        .then(
                            (orderId) =>
                                // Your code here after create the order
                                orderId
                        )
                }
                onApprove={function (data, actions) {
                    return actions.order.capture().then(() => {
                        window.location.href = 'https://app.imagetext.xyz/validate';
                    });
                }}
            />
        </>
    );
};

export default function App() {
    return (
        <div style={{ width: '100%' }}>
            <PayPalScriptProvider
                options={{
                    'client-id': clientId,
                    components: 'buttons',
                    currency: 'USD'
                }}
            >
                <ButtonWrapper currency={currency} showSpinner={false} />
            </PayPalScriptProvider>
        </div>
    );
}
