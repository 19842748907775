import { useLanguage } from "../../context/LanguageContext";
import { sendMessageToVoiceTypingExtension } from '../../services/helper';

export default () => {
  const { t } = useLanguage();
  const commandAlias = t("new_line_label");
  const description = t("command_newline_description_new");
  return {
    id: 'C52EC66E-9A89-29A8-42B3-4CC7B7132E6C',
    isNew: false,
    type: 'frontend',
    name: commandAlias,
    description,
    condition: "exact",
    match: [commandAlias],
    exec: async (text, options, callback) => {
      sendMessageToVoiceTypingExtension('/command', {type: 'NEW_LINE', text, options});
      callback();
    }
  };
};
