import imageToTextIcon from '../../assets/image_to_text.png';
import voiceTypingIcon from '../../assets/voice_typing.png';
import dictionaryIcon from '../../assets/dictionary.png';
import quizIcon from '../../assets/quiz.png';
import translateIcon from '../../assets/translate_icon.png';
import aiCommandIcon from '../../assets/aicommand.png'

const products = [
    {
        img: voiceTypingIcon,
        title: 'Voice Typing',
        author: '@fxnoob',
        rows: 2,
        cols: 3,
        featured: true,
        web: 'https://voicetyping.xyz/',
        chrome: 'https://chrome.google.com/webstore/detail/voice-typing/hmpihaioaacpehkghnkmnmgmihalkmdf'
    },
    {
        img: imageToTextIcon,
        title: 'Image to Text (OCR)',
        author: '@fxnoob',
        rows: 2,
        cols: 3,
        featured: true,
        web: 'https://imagetext.xyz/',
        chrome: 'https://chrome.google.com/webstore/detail/image-to-text-ocr/jgjlejdhmfpimggbicpffmpbnalcnhoo',
        edge: 'https://microsoftedge.microsoft.com/addons/detail/image-to-text-ocr/icgbomdceijejlokdmjpmgkojiliphma',
        opera: 'https://addons.opera.com/en/extensions/details/image-to-text-ocr/'
    },
    {
        img: aiCommandIcon,
        title: 'AI Command',
        author: '@fxnoob',
        isNew: true,
        rows: 3,
        cols: 3,
        featured: true,
        chrome: 'https://chrome.google.com/webstore/detail/ai-command/iinfmfmneepgdhlfeibcdmmdaflfjaic',
        web: 'https://aicommand.xyz/',
    },
    {
        img: quizIcon,
        title: 'CBSE & NCERT quiz',
        author: '@fxnoob',
        isNew: true,
        rows: 3,
        cols: 3,
        featured: true,
        chrome: 'https://chrome.google.com/webstore/detail/cbse-ncert-quiz/fhjhphocpoappbigjhcfeffndhjgikch',
        web: 'https://quizs.in/',
        android: 'https://play.google.com/store/apps/details?id=com.quizs.app',
    },
    {
        img: translateIcon,
        title: 'Translate',
        author: '@fxnoob',
        rows: 2,
        cols: 3,
        featured: true,
        chrome: 'https://chrome.google.com/webstore/detail/translate/pobhnbdhbklfjhlhpmoegnneonlnkbpo',
        edge: 'https://microsoftedge.microsoft.com/addons/detail/translate/fgdbpaadcpabolagoajbhapbhbmpolcm'
    },
    {
        img: dictionaryIcon,
        title: 'Dictionary Browser Extension',
        author: '@fxnoob',
        rows: 2,
        cols: 3,
        featured: true,
        chrome: 'https://chrome.google.com/webstore/detail/dictionary-oxford-webster/pbknajakomimhnnaiddkbddboifjolbf',
        edge: 'https://microsoftedge.microsoft.com/addons/detail/dictionary-oxford-webs/pbcjplaagkgfgihmbcbbmfdadhbekpeb',
        opera: 'https://addons.opera.com/en/extensions/details/dictionary-oxford-webster-and-wikipedia/'
    }
];

export default products;
