import React, { useEffect, useState } from 'react';

// material-ui
import { Button, Card, CardContent, Grid, Stack, Typography, CircularProgress, Container } from "@mui/material";

// project imports
import AnimateButton from '../../ui-component/extended/AnimateButton';
import { makePaymentForVoiceTyping } from '../../services/stripeService';
import constants from '../../constants';
import { useAuth } from '../../context/AuthContext';
import { useLanguage } from "../../context/LanguageContext";
import { getTrialInfo } from "../../services/helper";
import { useNavigate, useNavigation } from 'react-router-dom';

// ===========================|| PROFILE MENU - UPGRADE PLAN CARD ||=========================== //

const InfoCard = () => {
  const navigate = useNavigate()
  const [ loading, setLoading ] = useState(false);
  const [paid, setPaid] = useState(false);
  const { currentUser } = useAuth();
  const { t } = useLanguage();
  const [timeLeftLabel, setTimeLeftLabel] = useState("");
  const labelGoPremium = t('labelGoPremium'); // Go Premium
  const labelUpgradeToPro = t('upgradeToPro'); // Upgrade to Pro
  const labelForALifetime = t('labelForALifetime'); // for a lifetime.
  const labelMissingSomething = t('labelMissingSomething'); // Missing Something?
  const labelRequestAFeature = t('labelRequestAFeature'); // Request a Feature!
  const lRequest = t('request'); // Request
  const desc = `${constants.premium.pricing.mini} ${labelForALifetime}`;

  const makePayment = () => {
    navigate('/dashboard/payment')
  }

  useEffect(() => {
    setPaid(currentUser.paid);
    if (currentUser && currentUser.createdAt) {
      const { daysLeft } = getTrialInfo(currentUser.createdAt)
      const daysLeftFormatted = daysLeft <= 0 ? 0 : Math.floor(daysLeft)
      setTimeLeftLabel(`Only ${daysLeftFormatted} days left`)
    }
  }, [currentUser]);

  return (
    <>
      {paid && (
        <Card style={{ backgroundColor: "aliceblue" }}>
          <CardContent>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography variant="h4">{labelMissingSomething}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle2">
                  {labelRequestAFeature}
                </Typography>
              </Grid>
              <Grid item>
                <Stack direction="row">
                  <AnimateButton>
                    <Button variant="outlined" href={constants.support.feedbackForm} target="_blank">
                      {lRequest}
                    </Button>
                  </AnimateButton>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
      {currentUser && !currentUser.paid && (
        <Card style={{ backgroundColor: "aliceblue" }}>
          <CardContent>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography variant="h4">{labelUpgradeToPro}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle2">
                  {desc}
                </Typography>
                <Typography variant="subtitle2">
                  {timeLeftLabel}
                </Typography>
              </Grid>
              <Grid item>
                <Stack direction="row">
                  <AnimateButton>
                    {!loading ? <Button variant="outlined" onClick={makePayment} target="_blank">
                      {labelGoPremium}
                    </Button>: <Container><CircularProgress style={{width: '30px', height: '30px'}}/></Container>}
                  </AnimateButton>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default InfoCard;
