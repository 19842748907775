import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Grid from '@mui/material/Grid';
import { IconChartRadar } from '@tabler/icons';

export default function Faq() {
    return (
        <div style={{textAlign: 'center', marginTop: '3rem'}}>
            <h1 style={{ marginTop: '3rem', textAlign: 'center' }}>FAQs</h1>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <List sx={{ width: '100%' }}>
                        <ListItem alignItems="flex-start">
                            <ListItemAvatar>
                                <IconChartRadar />
                            </ListItemAvatar>
                            <ListItemText
                                primary="Do I need to pay for the service?"
                                secondary={
                                    <>
                                        {/* eslint-disable-next-line react/no-unescaped-entities */}
                                        No, you don't need to pay for service for the basic part. But if you want to use ad-free features,
                                        you can buy our premium plan.
                                    </>
                                }
                            />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                        <ListItem alignItems="flex-start">
                            <ListItemAvatar>
                                <IconChartRadar />
                            </ListItemAvatar>
                            <ListItemText
                                primary={<>How is <b>Voice Typing</b> different from other speech-to-text solutions?</>}
                                secondary={
                                    <>
                                        <b>Voice Typing</b> lets you use your voice to type on any website. Compared to speech-to-text software, like Dragon Dictation which cost $300 to $2500+, <b>Voice Typing</b> is affordable (free / $13 one time fee). Voice Typing works in the Chrome browser on all platforms including Mac, Windows, etc.
                                    </>
                                }
                            />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                        <ListItem alignItems="flex-start">
                            <ListItemAvatar>
                                <IconChartRadar />
                            </ListItemAvatar>
                            <ListItemText
                                primary="Can I type in my Native Language?. ie. Korean?"
                                secondary={
                                    <>
                                        {/* eslint-disable-next-line react/no-unescaped-entities */}
                                        <b>Voice Typing</b> lets you use your voice to type on any website in more than 100+ languages.
                                    </>
                                }
                            />
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={6}>
                    <List sx={{ width: '100%' }}>
                        <ListItem alignItems="flex-start">
                            <ListItemAvatar>
                                <IconChartRadar />
                            </ListItemAvatar>
                            <ListItemText
                                primary="Do I need to download browser extension ?"
                                secondary={
                                    <>
                                        {/* eslint-disable-next-line react/no-unescaped-entities */}
                                        To type on other pages, you need to download our <b>Voice Typing</b> Browser extension. For more, see Download page.
                                    </>
                                }
                            />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                        <ListItem alignItems="flex-start">
                            <ListItemAvatar>
                                <IconChartRadar />
                            </ListItemAvatar>
                            <ListItemText
                                primary={<>Does <b>Voice Typing</b> work on other websites like Word or Outlook?</>}
                                secondary={
                                    <>
                                        You can use <b>Voice Typing </b> on any website, after downloading our browser extension. See download page.
                                    </>
                                }
                            />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                        <ListItem alignItems="flex-start">
                            <ListItemAvatar>
                                <IconChartRadar />
                            </ListItemAvatar>
                            <ListItemText
                                primary={<>Can I use <b>Voice Typing</b> offline?</>}
                                secondary={
                                    <>
                                       <b>Voice Typing</b> requires a working internet connection and does not work offline.
                                    </>
                                }
                            />
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </div>
    );
}
