import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {useLanguage} from "../context/LanguageContext";

export default function OnboardingSteps({activeStep}) {
    const { t } = useLanguage();
    const labelOnboardStep1 = t('labelOnboardStep1'); // Mic Permissions
    const labelOnboardStep2 = t('labelOnboardStep2'); // Select Language
    const steps = [labelOnboardStep1, labelOnboardStep2];
    const step = activeStep || 0;
    return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={step} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
    );
}
