import { useLanguage } from "../../context/LanguageContext";
import { sendMessageToVoiceTypingExtension } from "../../services/helper";

export default () => {
  const { t } = useLanguage();
  const commandAlias = t("pressEnter"); // Press Enter
  const description = t("command_press_enter_description");
  return {
    id: '2C813AB6-109C-7BBE-50A5-B54CE1C30BD8',
    isNew: false,
    type: 'frontend',
    name: commandAlias,
    description,
    condition: "exact",
    match: [commandAlias],
    exec: async (text, options) => {
      sendMessageToVoiceTypingExtension('/command', {type: 'NEW_LINE', text, options});
    }
  };
};
