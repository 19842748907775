// material
import { styled } from '@mui/material/styles';
import { Toolbar, IconButton, Typography, OutlinedInput, InputAdornment, CircularProgress } from '@mui/material';
// component
import Iconify from '../../../components/Iconify';
import {useLanguage} from "../../../context/LanguageContext";
import {useAuth} from "../../../context/AuthContext";
import { updateUser } from '../../../services/firebaseService';
import React, { useState } from 'react';
// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

export default function UserListToolbar({ numSelected, filterName, onFilterName, onDelete, deleting }) {
  const { t } = useLanguage();
  const labelSearchCommands = t('labelSearchCommands'); // Search Commands

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <SearchStyle
          value={filterName}
          onChange={onFilterName}
          placeholder={`${labelSearchCommands}...`}
          startAdornment={
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          }
        />
      )}
      {numSelected > 0 & !deleting ? <IconButton onClick={onDelete}><Iconify icon="wpf:delete"/></IconButton>: ''}
      {numSelected > 0 & deleting ? <CircularProgress style={{width: '30px', height: '30px'}}/>: ''}
    </RootStyle>
  );
}
