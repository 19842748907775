// mui
import { ImageList, ImageListItem, ImageListItemBar, Container, Stack, Typography, Chip } from '@mui/material';

// components
import Page from '../components/Page';
import { ChromiumIcon, EdgeIcon, OperaIcon, WebIcon, AndroidIcon } from 'ui-component/BrowserStacks';
import ProductsConfig from '../sections/downloads/productsConfig';
import { useLanguage } from '../context/LanguageContext';


// ----------------------------------------------------------------------

export default function Downloads() {
const { t } = useLanguage()
const downloads = t('downloads'); // Downloads
  return (
     <Page title={downloads}>
     <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {downloads}
          </Typography>
        </Stack>
        <ImageList style={{ gridTemplateColumns: 'repeat(3,1fr)', gap: '4px' }}>
                {ProductsConfig.map((item) => (
                    <ImageListItem key={item.img} style={{ paddingTop: '1rem' }}>
                        <img src={`${item.img}`} style={{ width: 248 }} alt={item.title} loading="lazy" />
                        <ImageListItemBar
                            style={{ fontWeight: 'bold' }}
                            title={item.isNew ? <div>
                                {item.title + " " }
                            <Chip variant="outlined" color="info" size="small" label="New" />
                            </div>: item.title}
                            subtitle={<span>by: {item.author}</span>}
                            position="below"
                        />
                        <div style={{ display: 'flex' }}>
                            {item.web && <WebIcon link={item.web}/> }
                            {item.chrome && <ChromiumIcon link={item.chrome} />}
                            {item.edge && <EdgeIcon link={item.edge} />}
                            {item.opera && <OperaIcon link={item.opera} />}
                            {item.android && <AndroidIcon link={item.android} />}
                        </div>
                    </ImageListItem>
                ))}
            </ImageList>
     </Container>
     </Page>
  );
}
